<div class="displayTable">
  <div class="displayTableCell">
    <div class="authBlock">
      <img [src]="loginImage" alt="'Astronaut flying in the space'">
      <div class="login-card login-center">
        <div class="login-inside">
          <h4>Thank You for Registering</h4>
          <br>
          <div class="formGroup" *ngIf="authService.userData as user">
            <p class="text-center">
              We have sent a confirmation email to <strong>{{ user.email }}</strong
              >.
            </p>
            <p class="text-center">
              Please check your email and click on the link to verfiy your email
              address.
            </p>
          </div>

          <!-- Calling SendVerificationMail() method using authService Api -->
          <div class="formGroup">
        <button
          type="button"
          class="btn btnPrimary"
          (click)="authService.SendVerificationMail()"
        >
          <i class="fas fa-redo-alt"></i>
          Resend Verification Email
        </button>
          </div>
        </div>
        </div>

      </div> <!-- end of auth Block-->


    <div class="redirectToLogin">
      <span
        >Go back to?<span class="redirect" routerLink="/login">
          Log in</span
        ></span
      >
    </div>
    <div class="companyDetails">
      <div><a href="www.aimars.io">aimars.io</a></div>
      <div>AIMARS UG, Germany</div>
      <div>All rights reserved</div>
    </div>
  </div>
</div>
