import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { Observable, catchError, delay, from, map, of, switchMap, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class AuthGuard implements CanActivate {
  constructor(public authService: AuthService, public router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    //return of(true);

  return timer(500).pipe(  // Introduce a 500-millisecond delay
    switchMap(() => from(this.authService.isLoggedIn())),  // Call isLoggedIn after the delay
    switchMap(logged => {
      console.log('isLogged', logged);
      if (logged !== true) {
        console.log('============ AUTH GUARD: User is not logged in, redirecting to login...');
        this.router.navigate(['login']);
        return from([false]);
      } else {
        console.log('============ AUTH GUARD True -----------');
        return from([true]);
      }
    }),
    catchError(() => {
      this.router.navigate(['login']); // Handle errors
      console.log('============ AUTH GUARD Error -----------');
      return from([false]);
    })
  );
}

}
