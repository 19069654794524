import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { error } from 'console';
import { Interface } from 'readline';

export type GAEventCategory = 'Highlights' |'Insights'|'Data Hygiene Recommendations' | 'Navigation' | 'User Access' | 'Invite' | 'Integrate' ;

export type GAEventActionNav =  'Nav Menu Clicks' | 'User Menu Clicks' | 'App Logging' | 'App SignUp' | 'App Load' //Navigation
export type GAEventActionElements = 'Feedback Clicks' | 'Widget Clicks' | 'Hover Overs' | "Details Clicks" | 'Invite Member' | 'Un-invite Member' | 'Integrate for Org' | 'Delete Integration for Org'; //Highlights & Insights & Q-Insights
export type GAEventAction = GAEventActionNav | GAEventActionElements;

export type GATabName = 'Main Dashboard' | 'Highlights' |'Insights'|'Data Hygiene';
export type GAOptionName = 'User Profile' | 'Log Out';
export type GAEventLabelName = 
  'Tab Name Clicks' | 
  'Menu Option Clicks' | 
  'Avatar Clicks' | 
  'Thumbs Up' | 'Thumbs Down' | 'Comment' |
  'LogOut' | 'Register' | 'SignUp with User & Password'| 'LogIn with Google' | 'LogIn with User & Password' | 
  'Mouse over suggestion'|
  'Check Why' | 'Check Source' | 'Component Loading' | 'Invite Member' | 'Un-invite Member' | 'Integrate for Org' | GAEventActionElements;
export type GAEventLabelValue = string | number | GATabName | GAOptionName;

export interface GAEvent {
  _eventCategory: GAEventCategory,
  _eventAction: GAEventAction,
  _eventLabelName: GAEventLabelName,
  _eventLabelValue?: GAEventLabelValue,
  _eventDataId?: number | string//,
  //_eventUserId?: string;//google user id
  //_eventOrgId?: string;//ORG001
}

@Injectable({
  providedIn: 'root'
})

export class AnalyticsService {
  
  private loggedUserId!: any;

  constructor(public analytics: AngularFireAnalytics,
   // public auth: AuthService
    ) { 
      //this.userMeta = null;
      //this.userMeta = JSON.parse(localStorage.getItem('userMetadata')!);
  }
  userMeta: any = null;

  /*TestAnalytics() {
    return this.analytics.logEvent('clicks-otherclicks', {name: 'randomClick'}).finally(
      () => console.log('TestClick-other2', 'poszlo')
    );
  }*/

  TestAnalytics() {
    return this.analytics.logEvent('test-clicks', {
      eventCategory: 'Videos',
      eventAction: 'play',
      eventLabel: 'Fall Campaign'
    }
    ).finally(
      () => console.log('GA Event sent')
    );
  }

  public async LogClickEvent(
    //_eventName: string,
    _gaEvent: GAEvent

  ):Promise<void> {
    console.log('GA Event sent1');
    let loggedUserId = this.getUserId();
    let userOrg = this.getUserOrg();
    let _eventName = `${_gaEvent._eventCategory} -  ${_gaEvent._eventAction}`;
    try {
      console.log('Logging event to analytics:', {
        _eventName,
        event_category: _gaEvent._eventCategory,
        event_action: _gaEvent._eventAction,
        event_label_name: _gaEvent._eventLabelName,
        event_label_value: _gaEvent._eventLabelValue,
        event_data_id: _gaEvent._eventDataId,
        event_user_id: loggedUserId,
        event_org_id: userOrg
      });
  
      await this.analytics.logEvent(_eventName, {
        event_category: _gaEvent._eventCategory,
        event_action: _gaEvent._eventAction,
        event_label_name: _gaEvent._eventLabelName,
        event_label_value: _gaEvent._eventLabelValue,
        event_data_id: _gaEvent._eventDataId,
        event_user_id: loggedUserId,
        event_org_id: userOrg
      });
  
      console.log('GA Event sent:', {
        _eventName,
        ..._gaEvent,
        event_user_id: loggedUserId,
        event_org_id: userOrg
      });
  
      return Promise.resolve(); // Resolve the promise explicitly
  
    } catch (error) {
      console.error("GA Error", error);
      return Promise.reject(error); // Reject the promise explicitly
    }
  }

  getUserId(){
    this.loggedUserId = JSON.parse(localStorage.getItem('user')!);
    console.log('Logged user: ', this.loggedUserId);
    if (this.loggedUserId.uid == undefined) {
      return 'unknown user'
    } else {
      return this.loggedUserId.uid
    }
  }

  getUserOrg(){
    return this.userMeta = JSON.parse(localStorage.getItem('orgCodeCurr')!);
  }
/*
  setUserIdTracking(){
    this.analytics.setUserId()
    //G-V34M3VBSF0
  }
*/
}
