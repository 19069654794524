import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginImage:string = "./../../../../assets/images/brian-mcgowan-I0fDR8xtApA-unsplash 1.png";
  constructor(
    public authService: AuthService,
    //public afAuth: AngularFireAuth
  ) { }
  ngOnInit() { 
    console.log('Logging view')
    this.authService.clearLocalStorage();
    //this.authService.redirectToAppIfLogged();
  }
}
