import {
    animation, trigger, animateChild, group,
    transition, animate, style, query, state
  } from '@angular/animations';
  //examples: https://stackblitz.com/angular/qmpjdxkexvd?file=src%2Fapp%2Fapp.component.html
  
  export const transAnimation = animation([
    style({
      height: '{{ height }}',
      opacity: '{{ opacity }}',
      backgroundColor: '{{ backgroundColor }}'
    }),
    animate('{{ time }}')
  ]);

  export const fadeInOut = 
    trigger('fadeInOut', [
        state('initial', style({
          filter: 'blur(5px)' // Initial state with blur effect
        })),
        state('final', style({
          filter: 'blur(0px)' // Final state without blur effect
        })),
        transition('initial => final', animate('1000ms ease-out')) // Transition from initial to final state
      ])
  
  export const slideInOutAnimation = trigger('slideInOut', [
        state('initial', style({
          transform: 'translateX(100%)',
          opacity: 0
        })),
        state('final', style({
          transform: 'translateX(0)',
          opacity: 1
        })),
        transition('initial => final', animate('300ms ease-out')),
        transition('final => initial', animate('300ms ease-in'))
      ]);