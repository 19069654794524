import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, take, timeout } from 'rxjs/operators';
import { AuthService } from './../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> {
    console.log('>>>>>>>>>>>> Check Admin access to this route....')
    return this.authService.isAdmin().pipe(
      timeout(1000),
      take(1),
      map(isAdmin => {
        if (!isAdmin) {
          this.router.navigate(['/access-denied']);
          console.log('User is not admin in current org');
          return false;
        }
        console.log('User is admin in current org');
        return true;
      })
    );
    //return of(true);
  }
}