<div [@slideInOut]="animationState" *ngIf="this.alertMessage">
    <c-alert [color]="alertType" class="align-items-center" (click)="onClose()"
    [dismissible]="false"
    >
    <!--<svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:">
        <use xlink:href="#exclamation-triangle-fill" />
        [visible]="alertMessage"
    </svg>-->
    <div class="title"><svg cIcon name="cil-warning" size="xl"></svg><b>{{alertMessage.messageTitle || 'Error title here'}}</b></div>
    <hr>
    <div class="msg">{{alertMessage.message || 'Longer eror message can be here describing the problem in more details. Longer eror message can be here describing the problem in more details...'}}</div>
    <!--<hr>-->
    <div class="err"><b>Status: </b>{{alertMessage.errorStatus || '303, Forbidden'}}</div>
    </c-alert>
</div>
