<!--<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
  <c-container>
    <c-row class="justify-content-center">
      <c-col lg="7" md="9" xl="6">
        <c-card class="mx-4">
          <c-card-body class="p-4">
            <form cForm>
              <h1>Register</h1>
              <p class="text-medium-emphasis">Create your account</p>
              <c-input-group class="mb-3">
                <span cInputGroupText>
                  <svg cIcon name="cilUser"></svg>
                </span>
                <input autoComplete="name" cFormControl placeholder="Username" />
              </c-input-group>
              <c-input-group class="mb-3">
                <span cInputGroupText>@</span>
                <input autoComplete="email" cFormControl placeholder="Email" />
              </c-input-group>
              <c-input-group class="mb-3">
                <span cInputGroupText>
                  <svg cIcon name="cilLockLocked"></svg>
                </span>
                <input autoComplete="new-password" cFormControl placeholder="Password" type="password" />
              </c-input-group>
              <c-input-group class="mb-4">
                <span cInputGroupText>
                  <svg cIcon name="cilLockLocked"></svg>
                </span>
                <input autoComplete="new-password" cFormControl placeholder="Repeat password" type="password" />
              </c-input-group>
              <div class="d-grid">
                <button cButton color="success">Create Account</button>
              </div>
            </form>
          </c-card-body>
        </c-card>
      </c-col>
    </c-row>
  </c-container>
</div>-->

<div class="displayTable">
  <div class="displayTableCell">
    <div class="authBlock">
      <img [src]="loginImage" alt="'Astronaut flying in the space'">
      <div class="login-card">
        <div>
      <h4>Sign Up</h4>
      <br>
      <!-- Continue with Google -->
      <div class="formGroup">
        <button type="button" class="gsi-material-button" style="width:100%" (click)="authService.GoogleAuth()">
          <div class="gsi-material-button-state"></div>
          <div class="gsi-material-button-content-wrapper">
            <div class="gsi-material-button-icon">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
                <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                <path fill="none" d="M0 0h48v48H0z"></path>
              </svg>
            </div>
            <span class="gsi-material-button-contents">Sign up with Google</span>
            <span style="display: none;">Sign up with Google</span>
          </div>
        </button>
        <!--<button
          type="button"
          class="btn googleBtn"
          (click)="authService.GoogleAuth()"
        >
          <i class="fab fa-google-plus-g"></i>
          Continue with Google
        </button>-->
      </div>
      <div class="formGroup">
        <span class="or"><span class="orInner">or</span></span>
      </div>
      <div class="formGroup">
        <input
          type="email"
          class="formControl"
          placeholder="Email Address"
          #userEmail
          required
        />
      </div>

      <div class="formGroup">
        <input
          type="password"
          class="formControl"
          placeholder="Password"
          #userPwd
          required
        />
      </div>

      <div class="formGroup">
        <input
          type="button"
          class="btn btnPrimary"
          value="Sign Up"
          (click)="authService.SignUp(userEmail.value, userPwd.value)"
        />
      </div>

      

      <div *ngIf="this.authService.forcedSignout" class="forcedSignout">
        <span>You are not authorized to use this application. Contact AIMARS team to get access</span>
      </div>
    </div></div>
    </div>
    <div class="redirectToLogin">
      <span
        >Already have an account?
        <span class="redirect" routerLink="/login">Log In</span></span
      >
    </div>
    <div class="companyDetails">
      <div><a href="www.aimars.io">aimars.io</a></div>
      <div>AIMARS UG, Germany</div>
      <div>All rights reserved</div>
    </div>
  </div>
</div>
