import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";

@Component({
  selector: 'app-register-google',
  templateUrl: './register-google.component.html',
  styleUrls: ['./register-google.component.scss']
})
export class RegisterGoogleComponent implements OnInit {
  public loginImage:string = "./../../../../assets/images/brian-mcgowan-I0fDR8xtApA-unsplash 1.png";
  constructor(
    public authService: AuthService
  ) { }
  ngOnInit() { }
}