<c-modal  #verticallyCenteredModal #staticBackdropModal backdrop="static" id="staticBackdropModal" [visible]="showModal">
    <c-modal-header>
      <h5 cModalTitle>Welcome to Aimars!</h5>
      <!--<button [cModalToggle]="staticBackdropModal.id" cButtonClose></button>-->
    </c-modal-header>
    <c-modal-body>
        <span>You have <b>no organization</b> created for your account.</span>
        <br><br>
        <h3>Start Demo</h3>
        <button cButton color="primary" (click)="this.createDemoOrg()" [cModalToggle]="staticBackdropModal.id">Create Demo Organization</button>
        <br>
        <span class="or"><span class="orInner">or</span></span>
        <br>
        <h3>Contact Aimars</h3>
        <button cButton color="primary" (click)="this.goToAimarsContacts()">Onboard Aimars for your Organization</button>
    </c-modal-body>
    <c-modal-footer>
      <button [cModalToggle]="staticBackdropModal.id" cButton color="secondary" (click)="this.cancelAndLodOut()">
        Cancel and Logout
      </button>
    </c-modal-footer>
  </c-modal>
        <!--
        <c-modal  #verticallyCenteredModal #staticBackdropModal backdrop="static" id="liveDemoModal" [visible]="visible" (visibleChange)="handleModalChange($event)">
          <c-modal-header>
          <h5 cModalTitle>Your Comment</h5>
          <button (click)="toggleModal()" cButtonClose></button>
        </c-modal-header>
        <c-modal-body>
           
                <form cForm [formGroup]="simpleForm" novalidate (ngSubmit)="saveCommentChange()">
                      <textarea
                        
                        [(ngModel)]="commentText"
                        cFormControl
                        id="firstName"
                        formControlName="firstName"
                        placeholder="{{commentText}}"
                        autocomplete="off"
                        [autofocus]="true"
                        value="{{commentText}}"
                      ></textarea>
                </form>
        </c-modal-body>
        <c-modal-footer>
          <button (click)="toggleModal()" cButton color="secondary">Cancel</button>
          <button (click)="deleteComment()" cButton color="danger">Delete</button>
          <button (click)="saveCommentChange()" cButton color="primary">Save changes</button>
        </c-modal-footer>-->