import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, filter, map, skip } from 'rxjs';
import { DBsourceService } from './dbsource.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { User } from './user';
import { AuthService } from './auth.service';
import { isConstructorDeclaration } from 'typescript/lib/tsserverlibrary';
import { IMemberEntry, IOrgInvite, TOrgRole } from '../interfaces/invites';
import { OrgdataService } from './orgdata.service';
import { IOwnerLisItem } from '../interfaces/ownerdata';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { TFeatureCodes } from '../interfaces/datastructures';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class OrgsettingService {

  //public $orgChange = new BehaviorSubject<string>('');
  public $orgChange = new BehaviorSubject<string>('');
  //public _passUserAndOrg = new Subject<{user: User, currOrgCode: string}>();

  public userCurr!: User;
  
  constructor(
    private afs: AngularFirestore,
    private oDat: OrgdataService,
    private auth: AuthService,
    private afAuth: AngularFireAuth
              //public afs: AngularFirestore,
    ) { 

      console.log('load org settings service......')
      
      this.$orgChange.subscribe((orgCode)=> {
        console.log('Change org in local storage..');
        localStorage.setItem('orgCodeCurr', JSON.stringify(orgCode));
        JSON.parse(localStorage.getItem('orgCodeCurr')!);
      })

      /*this.auth._getCurrUser.subscribe((user)=>
      {
        
        this.userCurr = user;
        console.log('sub to user: ', this.userCurr.uid);
      })*/


      this.getLastOrgChange()
      .pipe(skip(1)) //first one goes before _passUserAndOrg
      .subscribe(orgCode => {
        console.log('ORG Listen to org changes in org data service', orgCode);
        this.oDat.currOrgCode = orgCode;
  
        this.afAuth.authState
        .subscribe((user:any) => {
  
          const showDataForMember: IOwnerLisItem = {
            teamLeaderEmail: user?.email+'',
            dealOwnerId: 999,
            dealOwnerEmail: user?.email+'',
            dealOwnerFullName: (user?.displayName) ? user?.displayName : null,
            uid: user?.uid
          }
  
          this.oDat._globalShowDataForEmail.next(showDataForMember);
          this.oDat.showDataForMember = showDataForMember;
          //update member details on org change:
          this.updateOrgMemberEntry(user, orgCode, true);//was:false
          
        });
        this.oDat.getOrgTeamMembers();
        this.auth._navPermit.next(null);
        //if(this.showDataForMember) this._globalShowDataForEmail.next(this.showDataForMember);
        
      })

      this.auth.getLastUserOrg().subscribe((orguser)=> {
        console.log('ORG -- Listen to org and user passed on load', orguser);
        this.oDat.currOrgCode = orguser.currOrgCode;
        this.oDat.currUser = orguser.user;
  
        const showDataForMember: IOwnerLisItem = {
          teamLeaderEmail: orguser.user?.email+'',
          dealOwnerId: 999,
          dealOwnerEmail: orguser.user?.email+'',
          dealOwnerFullName: (orguser.user?.displayName) ? orguser.user?.displayName : '',
          uid: orguser?.user?.uid
        }
        //this.oDat._globalShowDataForEmail.next(showDataForMember);
        this.$orgChange.next(orguser.currOrgCode);
        this.oDat.showDataForMember = showDataForMember;
        
        //this.oDat.getOrgTeamMembers();
      })
  
    }

    getLastOrgChange() {
      return this.$orgChange.asObservable();
    }

  changeOrg(orgCode: string){
    console.log('Change org to: ', orgCode);
    const user = JSON.parse(localStorage.getItem('user')!);
    //verify again if user has access to this org (someone can modify data in browser)
    //const giveAccessToOrg = this.db.giveOrgAccess(user,orgCode);
    if (true) {
      localStorage.setItem('orgCodeCurr', JSON.stringify(orgCode));
      JSON.parse(localStorage.getItem('orgCodeCurr')!);
      
      //////this.$orgChange.next(orgCode);

    }
    else {
      alert('You have no permissions to this organization');
    }
   

    //to do: based on org change trigger data refresh for all views...

  }

  public getCurrentUserName(){
    const user = JSON.parse(localStorage.getItem('user')!);
    return user?.displayName;
  }

  public daysQuarterRemaining(): number {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const endOfQuarter = new Date(year, month - month % 3 + 3, 0);
    const diffInTime = endOfQuarter.getTime() - today.getTime();
    const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));
    
    return diffInDays;
  }

  public addDemoOrg(){
    console.log("Add demo org...");
    //1. Add demo to user orgs
    const userJson = localStorage.getItem('user');
    const user = (userJson) ? JSON.parse(userJson) : null;
  
    let userData: User = user;
    const userRef: AngularFirestoreDocument<User> = this.afs.collection('users').doc(userData.uid);
    userRef.get().subscribe(doc => {
      let codes = ((doc.data()?.orgCodes) ? doc.data()?.orgCodes : []) as string[];
      let hasDemo = codes.includes('demo');
      if (!hasDemo){
        codes.push('demo');
        return userRef.update({
          orgCodes: codes
        })
        .then(() => {
            console.log("Demo org successfully added!");
            //2. Setup demo org as default
            localStorage.setItem('orgCodes', JSON.stringify(['demo']));
            JSON.parse(localStorage.getItem('orgCodes')!);
            localStorage.setItem('orgCodeCurr', JSON.stringify('demo'));
            JSON.parse(localStorage.getItem('orgCodeCurr')!);
            
        }).then(()=>{
          //this.changeOrg('demo');
          this.$orgChange.next('demo');
        })
        .catch((error) => {
            console.error("Error adding demo org: ", error);
        });
      } else {
          console.log("Demo already exists for this user.");
          return this.changeOrg('demo');
          //2. Setup demo org as default
        
      }
    });
    
  }

  public updateOrgWithRoles(user: User, orgInvite: IOrgInvite){
    console.log('INVITE: Update org with roles', user.uid,orgInvite );
    let userData: User = user;
    const userRef: AngularFirestoreDocument<User> = this.afs.collection('users').doc(userData.uid);
    userRef.get().subscribe(doc => {
      if(!doc.exists) {
        throw Error('No user doc exists');
      }
      let codes = ((doc.data()?.orgCodes) ? doc.data()?.orgCodes : []) as string[];
      let hasOrg = (codes) ? codes.includes(orgInvite?.orgCode) : false;
      if (!hasOrg){
        // add org to orgCodes list:
        console.log('INVITE: User has no org in orgCodes list', codes, orgInvite?.orgCode);
        codes.push(orgInvite?.orgCode);
        return userRef.update({
          orgCodes: codes
        })
       .then(()=>{
          //Create org doc with org access details
          console.log('INVITE: Updated access details for org: ', orgInvite.orgCode);
          this.updateOrgAccessDetails(user, orgInvite, null);
          this.updateOrgMemberEntry(user, orgInvite.orgCode, true);
          //this.checkOrgAccessDetailsAndUpdate(user,orgInvite);
          
        })
        .then(() => {
          console.log("INVITE: org successfully added!", orgInvite);
          //Setup new org as default in local storage & emit new org
          this.setOrgCodeEntriesLocalStorage(orgInvite);
          this.$orgChange.next(orgInvite.orgCode);
          
      })
        .catch((error) => {
            console.error("INVITE: Error adding new org: ", error);
        });
      } else {
          console.log(orgInvite.orgCode, "INVITE <--- This org already exists for this user. Check if roles changed..", orgInvite);
          this.checkOrgAccessDetailsAndUpdate(user,orgInvite);
          return this.updateOrgMemberEntry(user, orgInvite.orgCode, true);
          //return this.changeOrg(orgInvite?.orgCode);
          //2. Setup this org as default
        
      }
    });
    
  }
/*
  private checkOrgAccessDetailsAndUpdate(user: User, orgInvite: IOrgInvite){
    //get curr user org roles
    const currOrgDetails = this.afs
      .collection('users').doc(user?.uid)
      .collection('access-orgs').doc(orgInvite.orgCode).get()
      .subscribe(doc => {
        if (doc.exists){
          console.log('Current org access details:', doc.data());
        } else throw Error('Details for this org doesnt exists');
        
      });

    return this.updateOrgAccessDetails(user, orgInvite)
    
    //update
  }*/

  private async checkOrgAccessDetailsAndUpdate(user: User, orgInvite: IOrgInvite): Promise<void> {
    try {
      // Get the current user's org roles
      let prevInvite: IOrgInvite | any;

      const docRef = this.afs
        .collection('users').doc(user?.uid)
        .collection('access-orgs').doc(orgInvite?.orgCode);
  
      const doc = await docRef.get().toPromise();
      if (doc?.exists) {
        //console.log('Current org access details:', doc.data());
        prevInvite = doc?.data();
      } else {
        //throw new Error('Details for this org do not exist');
        this.updateOrgAccessDetails(user, orgInvite, null);
      }

      await this.updateOrgAccessDetails(user, orgInvite, prevInvite);
    } catch (error) {
      console.error('Error checking or updating org access details:', error);
      throw error; // Rethrow the error after logging it
    }
  }

  private updateOrgAccessDetails(user: User,orgInvite: IOrgInvite, prevInvite: IOrgInvite | null){
    
    const orgAccessDetails = {
      lastModified: new Date(),
      orgCode: orgInvite?.orgCode,
      orgRoles: this.updateOrgRoles(orgInvite?.orgRoles, prevInvite?.orgRoles),

    }

    return this.afs
      .collection('users').doc(user?.uid)
      .collection('access-orgs').doc(orgInvite.orgCode)
      .set(orgAccessDetails, { merge: true });
  }

// to do: when org exists, check roles change and apply
  private updateOrgRoles(newOrgRoles: TOrgRole[], oldOrgRoles: TOrgRole[]| undefined): TOrgRole[]{
    console.log('Old roles:', oldOrgRoles, "New roles:", newOrgRoles);
    if (!oldOrgRoles || oldOrgRoles.length == 0) { // when no prev roles, empty [] or not exists
      return newOrgRoles;
    } else {
      const oldSet = new Set(oldOrgRoles);
      const newSet = new Set(newOrgRoles);
      const missingNewRoles = newOrgRoles.filter(element => !oldSet.has(element));
      const tooMuchOldRoles = oldOrgRoles.filter(element => !newSet.has(element));
      console.log('Mising roles for org', missingNewRoles, "Too much old roles:", tooMuchOldRoles);
      
      let modOrgRoles: TOrgRole[] = [];
      //remove too much elements
      modOrgRoles = oldOrgRoles.filter((role:TOrgRole) => !tooMuchOldRoles.includes(role) ).concat(missingNewRoles);
      //add new elements
      //modOrgRoles.concat(missingNewRoles);

      console.log('New roles after complex update:', modOrgRoles);

      return modOrgRoles;
    }
  }

  private setOrgCodeEntriesLocalStorage(orgInvite: IOrgInvite){
    localStorage.setItem('orgCodes', JSON.stringify([orgInvite?.orgCode]));
    JSON.parse(localStorage.getItem('orgCodes')!);
    localStorage.setItem('orgCodeCurr', JSON.stringify(orgInvite?.orgCode));
    JSON.parse(localStorage.getItem('orgCodeCurr')!);
  }

  private async updateOrgMemberEntry(user: User, orgCode:string, onLogging: boolean) {
    const memberRef: AngularFirestoreDocument<IMemberEntry> = this.afs
    .collection('org-settings')
    .doc(orgCode)
    .collection('orgmembers')
    .doc(user.email);
    
    const dbUser: User | null = await this.auth.replaceUserDetailsFromDB(user);
    const doc = await memberRef.get().toPromise();
      if (doc?.exists) {
        const existingMember: IMemberEntry | any = doc?.data();
        const updatedMember: IMemberEntry = {
          fullName: (dbUser?.displayName) ? dbUser?.displayName : existingMember?.fullName || existingMember?.email,
          //fullName: (user?.displayName) ? user?.displayName : existingMember?.email,
          currentRoles: existingMember?.currentRoles,
          inviteStatus: 'signed',
          email: existingMember?.email,
          invitedMillis: existingMember?.invitedMillis,
          avatarUrl: user?.photoURL,
          uid: user?.uid,
          quotaSettings: {
            quotaPeriod: (existingMember?.quotaSettings) ? existingMember?.quotaSettings?.quotaPeriod : null,
            quotaAmount: (existingMember?.quotaSettings) ? existingMember?.quotaSettings?.quotaAmount : null
          },
          lastLoggedMillis: (onLogging) ? new Date().getTime() : existingMember?.lastLoggedMillis
        }

        return memberRef.set(updatedMember, {
          merge: true,
        })

      } else {
        // nothing to do
      }
  }

  public isCurrOrgDemo(): Observable<boolean> {
    return this.getLastOrgChange().pipe(
      map(orgCode => orgCode.includes('demo'))
    );
  }

  showFeature(featureCode: TFeatureCodes, orgId: string):boolean {

    const isPreviewFeature:boolean = environment.dataConfig.previewFeatures.previewComponents.includes(featureCode);
    const showPreviewFeature:boolean = environment.dataConfig.previewFeatures.showPreviewFeaturesOnlyFor.includes(orgId);

    if (isPreviewFeature && !showPreviewFeature) {
      return false;
    } else return true;
  }
    
  }



  
  

