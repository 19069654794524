import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';


import { NgScrollbarModule } from 'ngx-scrollbar';

//Import Firebase modules
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { environment } from '../environments/environment.dev';


//import { AnalyticsService} from './shared/services/analytics.service'
// Import routing module
import { AppRoutingModule } from './app-routing.module';

// Import app component
import { AppComponent } from './app.component';


// Import containers
import { DefaultFooterComponent, DefaultHeaderComponent, DefaultLayoutComponent } from './containers';
import { DefaultHeaderShoworgsComponent } from './containers/default-layout/default-header-showorgs/default-header-showorgs.component';
import { SelectPillsDropdownComponent } from './containers/default-layout/default-header/select-pills-dropdown/select-pills-dropdown.component';
import { ModalDemoorcontactComponent} from './containers/modals/modal-demoorcontact/modal-demoorcontact.component';
import { NgChartsModule } from 'ng2-charts';
import { AuthTokenHttpInterceptorProvider } from 'src/app/shared/http-interceptors/auth-token-interceptor';
import { KeysPipe} from 'src/app/shared/pipes/keypipe';

import {
  AvatarModule,
  BadgeModule,
  BreadcrumbModule,
  ButtonGroupModule,
  ButtonModule,
  CardModule,
  DropdownModule,
  FooterModule,
  FormModule,
  GridModule,
  HeaderModule,
  ListGroupModule,
  NavModule,
  ProgressModule,
  SharedModule,
  SidebarModule,
  TabsModule,
  UtilitiesModule,
  TableModule,
  AccordionModule,
  PlaceholderModule,
  ModalModule,
  TooltipModule,
  AlertModule
} from '@coreui/angular';

import { IconModule, IconSetService } from '@coreui/icons-angular';
import { AngularFireAnalyticsModule, ScreenTrackingService } from '@angular/fire/compat/analytics';
import { PopupAlertBeltComponent } from './containers/popups/popup-alert-belt/popup-alert-belt.component';
import { PopupConfirmComponent } from './containers/popups/popup-confirm/popup-confirm.component';
//import { DoDashboardComponent } from './views/do-dashboard/do-dashboard.component';
//import { DealDetailsComponent } from './views/deal-details/deal-details.component';
//import { AeDashboardComponent } from './views/ae-dashboard/ae-dashboard.component';
//import { HighlightsTableComponent } from './containers/highlights-table/highlights-table.component';

const APP_CONTAINERS = [
  DefaultFooterComponent,
  DefaultHeaderComponent,
  DefaultLayoutComponent,
  DefaultHeaderShoworgsComponent,
  SelectPillsDropdownComponent,
  KeysPipe
];

@NgModule({
  declarations: [AppComponent, ...APP_CONTAINERS, 
    //, DoDashboardComponent 
    //AeDashboardComponent, 
    //HighlightsTableComponent
  ],
  //exports: [NgChartsModule],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AngularFireAnalyticsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AvatarModule,
    BreadcrumbModule,
    FooterModule,
    DropdownModule,
    GridModule,
    HeaderModule,
    SidebarModule,
    IconModule,
    NavModule,
    ButtonModule,
    FormModule,
    UtilitiesModule,
    ButtonGroupModule,
    ReactiveFormsModule,
    SidebarModule,
    SharedModule,
    TabsModule,
    ListGroupModule,
    ProgressModule,
    BadgeModule,
    ListGroupModule,
    CardModule,
    NgScrollbarModule,
    HttpClientModule,
    TableModule,
    NgChartsModule,
    AccordionModule,
    PlaceholderModule,
    ModalModule,
    ModalDemoorcontactComponent,
    TooltipModule,
    AlertModule,
    PopupAlertBeltComponent,
    PopupConfirmComponent
  ],
  providers: [
    AuthTokenHttpInterceptorProvider,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    IconSetService,
    Title,
    ScreenTrackingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
