import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})

export class ForgotPasswordComponent implements OnInit {
  public loginImage:string = "./../../../../assets/images/brian-mcgowan-I0fDR8xtApA-unsplash 1.png";
  constructor(public authService: AuthService) {}

  ngOnInit() {}
}
