<div class="displayTable">
  <div class="displayTableCell">
    <div class="authBlock">
      <img [src]="loginImage" alt="'Astronaut flying in the space'">
      <div class="login-card login-center">
      <div>
      <h4>Reset Password</h4>
      <br>
      <p class="">
        Please enter your email address to request a password reset.
      </p>

      <div class="formGroup">
        <input
          type="email"
          class="formControl"
          placeholder="Email Address"
          #passwordResetEmail
          required
        />
      </div>

      <!-- Calling ForgotPassword from AuthService Api -->
      <div class="formGroup">
        <input
          type="submit"
          class="btn btnPrimary"
          value="Reset Password"
          (click)="authService.ForgotPassword(passwordResetEmail.value)"
        />
      </div>
    
      </div></div>
    </div><!-- enf of authBlock-->

    <div class="redirectToLogin">
      <span
        >Go back to ?
        <span class="redirect" routerLink="/login">Log In</span></span
      >
    </div>
    <div class="companyDetails">
      <div><a href="www.aimars.io">aimars.io</a></div>
      <div>AIMARS UG, Germany</div>
      <div>All rights reserved</div>
    </div>
  </div>
</div>
