export const environment = {
  
  production: false,
  /* DEV */
  firebase: {
    apiKey: "AIzaSyDVsgnN6-6sLivq-ju1A8eKZ6SMAJjI_0w",
    authDomain: "aimars-news.firebaseapp.com",
    projectId: "aimars-news",
    storageBucket: "aimars-news.appspot.com",
    messagingSenderId: "1034585405911",
    appId: "1:1034585405911:web:674058c073466ea7f77392",
    measurementId: "G-V34M3VBSF0",
    //apiUrl: "https://europe-west3-aimars-news.cloudfunctions.net/api", //"https://api-d4gscexcka-ey.a.run.app",
    functionsUrl: "https://europe-west3-aimars-news.cloudfunctions.net/upi",
  },
  switchOffConsole: false,
  dataConfig: {
    showUnsignedTeamMembersToUsers: true,
    showUnsignedTeamMembersOnlyIfAimars: true,
    oldDealsCreatedDaysAgo: 30,
    inactiveDealsActiveDaysAgo: 14,
    nonClientEmailMatch: ['aimars', 'gmail'],
    previewFeatures: {
      previewComponents: ['inny1', 'm1on1'],
      showPreviewFeaturesOnlyFor: ['demo', 'bookingkit', 'airborn_dev1', 'aimars', 'sftest'],
      hidePreviewFeaturesTabs: ['/m1on1-overview', '/m1on1-team'],
      hidePreviewSections: ['MANAGER 1 ON 1']
    }
  }

 /* PROD *//*
  firebase: {
    apiKey: "AIzaSyB8yxFVbOkZeFQ2y1dPR6L9iE1as9pxlcU",
    authDomain: "aimars.firebaseapp.com",
    projectId: "aimars",
    storageBucket: "aimars.appspot.com",
    messagingSenderId: "936905983363",
    appId: "1:936905983363:web:47431082e4822a58aaa2b6",
    measurementId: "G-92899FQ425"
  }*/
};
