import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ButtonModule, ModalBodyComponent, ModalFooterComponent, ModalModule } from '@coreui/angular';
import { AuthService } from 'src/app/shared/services/auth.service';
import { OrgsettingService } from 'src/app/shared/services/orgsetting.service';

@Component({
  selector: 'app-modal-demoorcontact',
  imports: [ModalModule, ButtonModule],
  standalone: true,
  templateUrl: './modal-demoorcontact.component.html',
  styleUrls: ['./modal-demoorcontact.component.scss']
})
export class ModalDemoorcontactComponent /*implements OnChanges*/ {

  constructor(private auth: AuthService,
              private orgSet: OrgsettingService
  ){}

  @Input() showModal: boolean = false;
  //public showModal2: boolean = false;

  /*
  ngOnChanges(): void {
    this.showModal2 = this.showModal;
    console.log('Show modal: ', this.showModal2);
  }*/

  public goToAimarsContacts() {
    var url = "https://www.aimars.io"; // Change this to the desired URL
    var win = window.open(url, '_blank');
    this.auth.SignOut();
    //win.focus();
  }

  public createDemoOrg(){
    this.orgSet.addDemoOrg();
  }

  public cancelAndLodOut(){
    this.auth.SignOut();
  };
}


