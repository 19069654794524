import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';

import { Observable, Subject, from, switchMap } from 'rxjs';
import { IComment, IDataType, IHighlight, IInsight, IQSuggestion } from '../interfaces/hi';
import { emit } from 'process';
import { resolve } from 'path';
//import { IOrgCode, IOrgName } from './user';
import { AuthService } from './auth.service';
import { User } from './user';
//import { AuthService } from './auth.service';
//import { IOrgCode, IOrgName } from './user';

export type IFileNameInclude = 'highlights' | 'insights' | 'qualitysuggestions';
export interface IArrayOfFiles {
  fileName: string;
  fullPath: string; 
};

@Injectable({
  providedIn: 'root'
})

export class DBsourceService {


  private giveAccess:boolean = false;
  private orgCodes: any;

  giveOrgAccess(user: any, orgCode:string):boolean {
    const userRef: AngularFirestoreDocument<User> = this.afs.collection('users').doc(user.uid);
    userRef.get().subscribe(doc => {
      const orgCodes = doc.data()?.orgCodes;
      this.giveAccess = (orgCodes) ? orgCodes?.some(code => code == orgCode) : false;
      console.log('Give access? ', this.giveAccess);
    })
    return this.giveAccess;
  }

  getOrgList(user:any):string[]{
    const userRef: AngularFirestoreDocument<User> = this.afs.collection('users').doc(user.uid);
    userRef.get().subscribe(doc => {
      this.orgCodes = doc.data()?.orgCodes;
      console.log('OrgList: ', this.getOrgList);
    })
    return this.orgCodes;
  }

  //private sourceURL = ''; // Replace with the path to your local JSON file

  //private _getQSuggestions = new Observable<IQSuggestion[]>();
  private _getJsonFile = new Subject<boolean>();
  public _getHighlights = new Subject<IHighlight[]>;
  public _getInsights = new Subject<IInsight[]>;
  public _getQSuggestions = new Subject<IQSuggestion[]>;

  public _getArchivedInsights = new Subject<IInsight[]>;
  public _getArchivedHighlights = new Subject<IHighlight[]>;
  public _getArchivedQSuggestions = new Subject<IQSuggestion[]>;

  //public _getOrgCode = new Subject<IOrgCode>(); 
  public _getOrgCode = new Subject<string>(); 

  arch_dataHighlights_all: IHighlight[]=[];
  arch_dataInsights_all: IInsight[]=[];
  arch_dataQuality_all: IQSuggestion[]=[];
  curr_dataHighlights_all: IHighlight[]=[];
  curr_dataInsights_all: IInsight[]=[];
  curr_dataQuality_all: IQSuggestion[]=[];

  //loading identificator
  initCurrHighllightsLoading: boolean = true;
  initCurrInsightsLoading: boolean = true;
  initCurrQSuggestionsLoading: boolean = true;
  initArchQSuggestionsLoading: boolean = true;
  initArchDataLoading: boolean = true;

  public _archDataLoaded = new Subject<boolean>();
  //private userOrgName: IOrgName;
  private userOrgName!: string;//IOrgName;

  userMetadata: any;

  constructor(
    private http: HttpClient,
    public afs: AngularFirestore, // Inject Firestore service
    private storage: AngularFireStorage,
    //private auth: AuthService
    ) { 
      /*
      this.userOrgName = JSON.parse(localStorage.getItem('userMetadata')!).org; //'sparrks'//this.orgCodeToName();
      console.log('User org for db: ', this.userOrgName);

      this.storedHighlightsPath =`${this.userOrgName}/${this.storedHighlightsPathFile}`;
      this.storedInsightsPath = `${this.userOrgName}/${this.storedInsightsPathFile}`;
      this.storedQualitySuggestionsPath = `${this.userOrgName}/${this.storedQualitySuggestionsPathFile}`;
      this.arch_storedPath = `${this.userOrgName}/${this.arch_storedPathInOrg}`
      */

      
    }
  /*data: any; // Replace 'any' with the appropriate type for your data

  getDataQuality() {
    //console.log('getdata quality');
    this.getData().subscribe(
      (response) => {
        console.log('getdata quality',response); // The data from the local JSON file will be stored in the 'data' variable
        this.data = response;
      },
      (error) => {
        console.error('Error loading local JSON data:', error);
      }
    );
  }*/
/*
  getData2(sourceURL: any, resType: any = {responseType: 'json'}) {
    return this.http.get<any>(sourceURL, resType);
  }
*/
  getData3(sourceURL: any) {
    return this.http.get<any>(sourceURL, {
      responseType: 'json'
    });
  }

  getImage(sourceURL: any) {
    return this.http.get(sourceURL, {responseType:'text'});
  }
/*
  getPhotoURL() {
    return this.http.get<any>('https://lh3.googleusercontent.com/a/AAcHTtfUh8tgfvo1RqQVSh-yHG2Fe8JKcK7b3HCjvN3ZL0V8gCs=s96-c', 
    );
    
  }*/

  _appConfig!: Observable<any>;
  _appAccess!: Observable<any>;
  _feedbackComment!: Observable<any>;

  getApprovedEmails(){
    this._appConfig = this.afs.doc('app-config/app-config').valueChanges();
    //console.log('Collection Config: ',this._appConfig.subscribe(res => res));
    return this._appConfig;
    
  };
/*
  getUserMetadata(userEmail:string|null|undefined){
    this._appAccess = this.afs.doc(`app-access/${userEmail}`).valueChanges();
    //console.log('App Config for user: ',this._appAccess.subscribe(res => res));
    return this._appAccess;
  }*/

  getCommentById(itemId: number, itemType: "H" | "I" |"Q"){
    const user = JSON.parse(localStorage.getItem('user')!);
    this._feedbackComment = this.afs.doc(`users/${user.uid}/feedback-${itemType}/${itemId}`).valueChanges();
    //this._feedbackComment = this.afs.doc(`users/DesoEiHE80cJtK1LvwTLS3ZabFF2/feedback/feedback-h`).valueChanges();
    //console.log('Comments for user: ',this._feedbackComment.subscribe(f=>f));
    return this._feedbackComment;

  }

  /*Storage*/
  fileUrl: Observable<string | null> | undefined;
  public jsonDataHighlights: IHighlight[]=[];
  public jsonDataInsights: IInsight[]=[];
  public jsonQuailitySuggestions: IQSuggestion[]=[];
  /*
  async loadJsonFile() {
    const ref = this.storage.ref('sparrks/current_highlights.json');
    //this.fileUrl = 
    ref.getDownloadURL().subscribe(f => {
     //console.log('Json url: ',f);
     this.getData3(f).subscribe(data => {
      //console.log('Json url: ',data);
      this.jsonDataHighlights = data;
     });
    });
    console.log('Json data: ',this.jsonDataHighlights);
    return this.jsonDataHighlights;
}*/
storedHighlightsPath: any;
storedInsightsPath: any;
storedQualitySuggestionsPath: any;
arch_storedPath: any;

readonly storedHighlightsPathFile ='current_highlights.json';
readonly storedInsightsPathFile = 'current_insights.json';
readonly storedQualitySuggestionsPathFile = 'current_qualitysuggestions.json';

readonly arch_storedPathInOrg = 'archived';

async loadJsonFile2(storagePath: string) {
  const ref = this.storage.ref(storagePath);
  return ref.getDownloadURL();
}

loadJsonFile(storagePath: string): Observable<any> {
  return from(this.storage.ref(storagePath)
    .getDownloadURL())
    .pipe(
      switchMap((sourceURL) => this.http.get<any>(sourceURL, { responseType: 'json' as 'json' })
    )
  );
}
/*
async getDataHighlights2() {

  this._getOrgCode.subscribe((userOrgName)=>{
    this.storedHighlightsPath =`${userOrgName}/${this.storedHighlightsPathFile}`;
    this.storedInsightsPath = `${userOrgName}/${this.storedInsightsPathFile}`;
    this.storedQualitySuggestionsPath = `${userOrgName}/${this.storedQualitySuggestionsPathFile}`;
    this.arch_storedPath = `${userOrgName}/${this.arch_storedPathInOrg}`

    this.initCurrHighllightsLoading = true;
  //get file url
    let f = this.loadJsonFile2(this.storedHighlightsPath);
    this.getData3(f).subscribe(
      (data:any) => {
        console.log('Json url in db service for highlights: ',data);
      this.jsonDataHighlights = data.filter((highlight: { rel_insights: string | any[]; }) => highlight.rel_insights.length==0);
      return this._getHighlights.next(this.jsonDataHighlights);
     },
     (error) => {
      console.error('Error loading local JSON highlights data:', error);
    })

    })

}*/

async getDataHighlights2() {
  //this.userOrgName = this.orgCodeToName();
  //console.log('this.userOrgName',this.userOrgName);
  this.storedHighlightsPath =`${this.userOrgName}/${this.storedHighlightsPathFile}`;
  //this.storedInsightsPath = `${this.userOrgName}/${this.storedInsightsPathFile}`;
  //this.storedQualitySuggestionsPath = `${this.userOrgName}/${this.storedQualitySuggestionsPathFile}`;
  //this.arch_storedPath = `${this.userOrgName}/${this.arch_storedPathInOrg}`
  //this.initCurrHighllightsLoading = true;
  //get file url
  (await this.loadJsonFile2(this.storedHighlightsPath)).subscribe(f =>
    this.getData3(f).subscribe(
      (data:any) => {
        console.log('Json url in db service for highlights: ',data);
      this.jsonDataHighlights = data.filter((highlight: { rel_insights: string | any[]; }) => highlight.rel_insights.length==0);
      return this._getHighlights.next(this.jsonDataHighlights);
     },
     (error) => {
      console.error('Error loading local JSON highlights data:', error);
    })
  ); 

}


async getDataInsights2() {
  //get file url
  //this.userOrgName = this.orgCodeToName();
  //console.log('this.userOrgName',this.userOrgName);
  //this.storedHighlightsPath =`${this.userOrgName}/${this.storedHighlightsPathFile}`;
  this.storedInsightsPath = `${this.userOrgName}/${this.storedInsightsPathFile}`;
  //this.storedQualitySuggestionsPath = `${this.userOrgName}/${this.storedQualitySuggestionsPathFile}`;
  //this.arch_storedPath = `${this.userOrgName}/${this.arch_storedPathInOrg}`
  //this.initCurrHighllightsLoading = true;
  this.initCurrInsightsLoading = true;
  (await this.loadJsonFile2(this.storedInsightsPath)).subscribe(f =>
    this.getData3(f).subscribe(
      (data) => {
      console.log('Json url in db service for insights: ',data);
      this.jsonDataInsights = data;
      return this._getInsights.next(data);
     // return this.jsonDataInsights;
     },
     (error) => {
      console.error('Error loading local JSON jsonDataInsights data:', error);
    })
  ); 
}

async getDataQualitySuggestions2() {
  //this.userOrgName = this.orgCodeToName();
  //console.log('this.userOrgName',this.userOrgName);
  //this.storedHighlightsPath =`${this.userOrgName}/${this.storedHighlightsPathFile}`;
  //this.storedInsightsPath = `${this.userOrgName}/${this.storedInsightsPathFile}`;
  this.storedQualitySuggestionsPath = `${this.userOrgName}/${this.storedQualitySuggestionsPathFile}`;
  //this.arch_storedPath = `${this.userOrgName}/${this.arch_storedPathInOrg}`
  //this.initCurrHighllightsLoading = true;
  //get file url
  this.initCurrQSuggestionsLoading = true;
  (await this.loadJsonFile2(this.storedQualitySuggestionsPath)).subscribe(f =>
    this.getData3(f).subscribe(
      (data: IQSuggestion[]) => {
      console.log('Json url in db service for quality: ',data);
      this.jsonQuailitySuggestions = data;
      this.propsStringToArray(this.jsonQuailitySuggestions);
      return this._getQSuggestions.next(data);
     },
     (error) => {
      console.error('Error loading local JSON quality data:', error);
    })
  ); 
}


// GET ARCHIVED DATA:

  /*
async listFilesAll(storagePath: string) {
    const ref = this.storage.ref(storagePath).listAll().subscribe(list => {
      console.log('List all', list.items);
      list.items.forEach((itemRef) => {
        // All the items urls under listRef.
        let itemUrl = itemRef.getDownloadURL().then(url => console.log('List all items', url));
      });
      return list.items;
    })
  };*/

  //remember to add paging when number of files increase drastically > 50 files
  readonly limitAllFilesList = 50;
  //itemsByFileHighlights:any = [];
  //mergedData:any = []; 
  async getAllArchDataByType(filenameInclude: IFileNameInclude){
    //this.userOrgName = this.orgCodeToName();
    //console.log('this.userOrgName',this.userOrgName);
    this.arch_storedPath = `${this.userOrgName}/${this.arch_storedPathInOrg}`
    //this.initCurrHighllightsLoading = true;
    this.initArchDataLoading = true;
    //this.itemsByFileHighlights = [];
    let mergedData:any = []; //IHighlight[] | IInsight[] | IQSuggestion = [];

    const firstPage = this.storage.ref(this.arch_storedPath).list({ maxResults: this.limitAllFilesList });
    firstPage.subscribe(list => {
      console.log('List all', list.items);
      list.items.forEach((itemRef) => {

        if (itemRef.name.includes(filenameInclude)) {
          const ref = this.storage.ref(itemRef.fullPath).getDownloadURL().subscribe(url=>
            this.getData3(url).subscribe(data => {
              //console.log('Get dat from one file: ',url ,data);
              mergedData = mergedData.concat(data).sort((a:IHighlight|IInsight|IQSuggestion,b:IHighlight|IInsight|IQSuggestion) => {if(a.createdDate > b.createdDate) {return -1} else return 1});
              
              switch(filenameInclude){
                case 'highlights': {
                  let mergedAndFiltered = mergedData.filter((highlight: { rel_insights: string | any[]; }) => highlight.rel_insights.length==0)
                  this._getArchivedHighlights.next(mergedAndFiltered);
                  break;
                }
                case 'insights':{
                  this._getArchivedInsights.next(mergedData);
                  break;
                }
                case 'qualitysuggestions': {
                  this._getArchivedQSuggestions.next(mergedData);
                  break;
                }
              }
              
            }
          ))
        }
    })
    //console.log('Array of files', filenameInclude, arrayOfFiles);
    
    })//subscribe
}

findMaxCreatedDate(jsonArray: IHighlight[] | IInsight[] | IQSuggestion[]):string {
  let maxDate: string = "2000-00-00";
  for (let key in jsonArray){
  let name = jsonArray[key].createdDate;
    if (name > maxDate) {
      maxDate = name;
    }
}
  console.log('Name,',maxDate);
  return maxDate;
}
//get each file by type
//merge to one json
//paginate?

/*
async getDataInsights2() {
  //get file url
  (await this.loadJsonFile2(this.storedInsightsPath)).subscribe(f =>
      fetch(f)
      .then(res => res.json()) // the .json() method parses the JSON response into a JS object literal
      .then(data => 
        {
          console.log(data);
          console.log('Json url in db service for insights: ',data);
          this.jsonDataInsights = data;
          console.log('testing json:',this.jsonDataInsights[3].rel_highlightText[0])
          return this.jsonDataInsights;
        })
      .catch(err => {
        console.error(err);
      })
    )
}*/



/*
subscribeToCommentById(itemId: number, dataType: IDataType):Promise<any> {
  const promise = new Promise<void>((resolve, reject) => {
    let itemType = this.convertDataType(dataType);

    this.getCommentById(itemId,itemType).subscribe({
      next: (res: any) => {
        //this.data = 
        //res.map((res: any) => {
          console.log('Read comment', res);
          return res;
        //});
        resolve();
      },
      error: (err: any) => {
        reject(err);
      },
      complete: () => {
        console.log('complete');
      },
    });
  });
  return promise;
}*/

/*
async subscribeToCommentById(itemId: number, dataType: IDataType) {
  let itemType = this.convertDataType(dataType);
  this.getCommentById(itemId,itemType).subscribe(
    (comment:any) => {
      console.log('Read comment', comment);
      return comment;
    },
    (error) => {
      console.error('Error loading comment', error);
    });
}
*/

convertDataType(dataType: IDataType): "H"|"I"|"Q" {
  let type: "H"|"I"|"Q"  = "H";
  switch (dataType) {
    case 'Insights': { 
      type ='I';
      break;
    }
    case 'Highlights': {
      type = 'H';
      break;
    }
    case 'Data Hygiene Recommendations': {
      type = 'Q';
      break;
    }
    default: break;
}
return type;
}

setComment(dataType: IDataType, itemId: number, newComment: IComment) {
  const user = JSON.parse(localStorage.getItem('user')!);
  let itemType = this.convertDataType(dataType);
  const userRef: AngularFirestoreDocument<any> = 
    this.afs.doc(`users/${user.uid}/feedback-${itemType}/${itemId}`);

  return userRef.set(newComment, {
    merge: true,
  });
}

deleteComment(dataType: IDataType, itemId: number){
  const user = JSON.parse(localStorage.getItem('user')!);
  let itemType = this.convertDataType(dataType);
  const userRef: AngularFirestoreDocument<any> = 
    this.afs.doc(`users/${user.uid}/feedback-${itemType}/${itemId}`);

  return userRef.delete();
}

/*
getDataQualitySuggestions3(){

  this._getJsonFile.subscribe(f=>{
    return this.loadJsonFile2(this.storedQualitySuggestionsPath);
  });

  

  
  this._getQSuggestions.pipe().subscribe(sug => {
    this._getJsonFile.next(true)
    
    (await this.loadJsonFile2(this.storedQualitySuggestionsPath)).subscribe(f =>
      this.getData3(f).subscribe(
        (data: IQSuggestion[]) => {
        console.log('Json url in db service for quality: ',data);
        this.jsonQuailitySuggestions = data;
        this.propsStringToArray();
        return this.jsonQuailitySuggestions;
       },
       (error) => {
        console.error('Error loading local JSON quality data:', error);
      })
    );
  })
}
*/
propsStringToArray(fromJson:IQSuggestion[]){
  let stringWithSemi = fromJson;
  stringWithSemi.forEach(qInsight => {
    qInsight.rel_fields_array = qInsight.rel_fields.replaceAll(' ','').split(";");
  })

  console.log('data: ', stringWithSemi);

  return true;
}





/*
async loadJsonFile2() {
  const ref = this.storage.ref('sparrks/current_highlights.json');
  this.getData3(ref).subscribe(jsonData => {
      this.jsonDataHighlights = jsonData;
      console.log('Json data2: ',this.jsonDataHighlights);
      return this.jsonDataHighlights;
    },
    (error) => {
      console.error('Error loading local JSON highlights data:', error);
    }
  );
  }
  */

  private getUserOrg(){
    return JSON.parse(localStorage.getItem('userMetadata')!).org;
  }
/*
  private orgCodeToName():IOrgName{
    let orgCode: IOrgCode = this.getUserOrg();
    switch(orgCode) { 
      case 'ORG000': { 
         return 'testcompany'
         break; 
      } 
      case 'ORG001': { 
        return 'sparrks'
        break; 
     } 
     case 'ORG002': { 
      return 'expertc'
      break; 
   } 
      default: { 

         //statements; 
         return undefined
         break; 
      } 
   }
  }
*/
/*
  async getUserMetadata(loggingEmail:string|null|undefined){
    console.log('get user metadata like org');
    const docRef = this.afs.doc(`app-access/${loggingEmail}`);
    docRef.get().subscribe( 
      (doc: any) => {
        //let meta = {"org":"ORG000"};
        this.userMetadata = doc.data();
        localStorage.setItem('userMetadata', JSON.stringify(this.userMetadata));
        //this._getOrgCode.next(this.userMetadata);
        console.log('meta',doc);
        return this.userMetadata;
      },
      (error) => {}
    );  
  }
*/
}
