import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentSnapshot } from '@angular/fire/compat/firestore';
import { ICardInsightsItem, IInsightState, TDealEngScoreDetailsType } from '../interfaces/dealdata';
import { AuthService } from './auth.service';
import { User } from './user';
import { ICardInsights1on1Item } from '../interfaces/ownerdata';
import { Subject } from 'rxjs';
import { IOffCanva1on1PatternDetails, IOffCanvaDetails, TInsightCategory, TInsightLevel } from '../interfaces/datastructures';
import { ICardInsights1on1PatternItem } from '../interfaces/m1on1data';

@Injectable({
  providedIn: 'root'
})
export class ActivitydataService {

  
  public _showDetailsInOffCanva = new Subject<IOffCanvaDetails>(); //view operations
  public _show1on1DetailsInOffCanva = new Subject<IOffCanva1on1PatternDetails>(); //view operations
  public _higlightDetailsType = new Subject<TDealEngScoreDetailsType|null>(); //view operations
  public _activateInsightsSwitcher = new Subject<IInsightState>();

  currUser!: User;
  constructor(public afs: AngularFirestore,
              private auth: AuthService
    ) {

      this.auth._getCurrUser.subscribe(user => {
      
        this.currUser = user;
        //console.log('Subi to user: ', this.currUser.uid);
      })
    }

  async updateInsight<T extends ICardInsightsItem | ICardInsights1on1Item | ICardInsights1on1PatternItem>(changedInsight: T, insightCategory: TInsightCategory): Promise<void[]> { //, insightType: TInsightCategory
    const promises: Promise<void>[] = [];
    const currOrg = JSON.parse(localStorage.getItem('orgCodeCurr')!);
    
    changedInsight.lastStatusBy = this.currUser.email;
    changedInsight.lastUpdated = new Date().getTime();

    //if ('category' in changedInsight && 'topic' in changedInsight) { // category1 exists on 1 on 1 insights

    console.log('INSIGHT | update ', insightCategory, changedInsight);

    switch(insightCategory) {
      case 'oneonone' : {

        const org1on1DealActivitiesRef = this.afs
        .collection('org-data')?.doc(currOrg)
        .collection('deal-activity')?.doc(changedInsight?.dealId?.toString())
        .collection('deal-1on1-insights')?.doc(changedInsight?.insightId?.toString());
        promises.push(org1on1DealActivitiesRef.set(changedInsight, { merge: true }));
  
        const user1on1ActivitiesRef = this.afs
          .collection('users')?.doc(this.currUser.uid)
          .collection('user-orgs')?.doc(currOrg)
          .collection('user-1on1-insights')?.doc(changedInsight?.insightId?.toString());
        promises.push(user1on1ActivitiesRef.set(changedInsight, { merge: true }));
        
        break;
        }

      case 'deal' : {
        const orgDealActivitiesRef = this.afs
        .collection('org-data')?.doc(currOrg)
        .collection('deal-activity')?.doc(changedInsight?.dealId?.toString())
        .collection('deal-insights')?.doc(changedInsight?.insightId?.toString());
        promises.push(orgDealActivitiesRef.set(changedInsight, { merge: true }));

        const userDealActivitiesRef = this.afs
          .collection('users')?.doc(this.currUser.uid)
          .collection('user-orgs')?.doc(currOrg)
          .collection('user-activity-insights')?.doc(changedInsight?.insightId?.toString());
        promises.push(userDealActivitiesRef.set(changedInsight, { merge: true }));
        
        break;
      }

      case 'oneonone-pattern' : {

        const orgDealActivitiesRef = this.afs
        .collection('org-data')?.doc(currOrg)
        .collection('org-activity')?.doc(currOrg)
        .collection('pattern-1on1-insights')?.doc(changedInsight?.insightId?.toString());
        promises.push(orgDealActivitiesRef.set(changedInsight, { merge: true }));

        const userDealActivitiesRef = this.afs
          .collection('users')?.doc(this.currUser.uid)
          .collection('user-orgs')?.doc(currOrg)
          .collection('user-activity-pattern-1on1-insights')?.doc(changedInsight?.insightId?.toString());
        
        promises.push(userDealActivitiesRef.set(changedInsight, { merge: true }));
        
        break;
      }
    }

    return Promise.all(promises);
  }

  async getModifiedInsightsByType(uid: string | null, dealId: string | null | string[], currOrg: string, insightCategory: TInsightCategory, insightLevel: TInsightLevel) { //same code as above except firebase node (to unify)
    try {
      let insightsRefCol: AngularFirestoreCollection<AngularFirestoreDocument<ICardInsightsItem>[]>| null = null;
      //console.log('INSMOD | Get modified insights', currOrg);
      if (!uid && !dealId) {
        throw Error('INSMOD | Missing parameter to update insights');
      }

      switch(insightCategory) {
        case 'deal' : {
          if (insightLevel === 'deal' && dealId) {
            //console.log('INSMOD: insight - org deal')
            insightsRefCol = this.afs
            .collection('org-data')?.doc(currOrg)
            .collection('deal-activity')?.doc(dealId?.toString())
            .collection('deal-insights');
          }
  
          if (insightLevel === 'owner' && uid) {
            //console.log('INSMOD: insight - owner deal')
            insightsRefCol = this.afs
            .collection('users')?.doc(uid ? uid : '') //
            .collection('user-orgs')?.doc(currOrg)
            .collection('user-activity-insights'); 
          }
          break;
        }
        case 'oneonone' : {
          if (insightLevel === 'deal' && dealId) {
            //console.log('INSMOD: 1 on 1 - org 1on1')
            insightsRefCol = this.afs
            .collection('org-data')?.doc(currOrg)
            .collection('deal-activity')?.doc(dealId?.toString())
            .collection('deal-1on1-insights');
          }
  
          if (insightLevel === 'owner' && uid) {
            //console.log('INSMOD: 1 on 1 - owner 1on1')
            insightsRefCol = this.afs
              .collection('users')?.doc(uid ? uid : '') //
              .collection('user-orgs')?.doc(currOrg)
              .collection('user-1on1-insights');
          }
          break;
        }
        case 'oneonone-pattern' : {
          //console.log('INSMOD: 1 on 1 patterns')
          if (insightLevel === 'org') {
            //console.log('INSMOD: 1 on 1 - org 1on1')
            insightsRefCol = this.afs
            .collection('org-data')?.doc(currOrg)
            .collection('org-activity')?.doc(currOrg)
            .collection('pattern-1on1-insights');
          }
  
          if (insightLevel === 'owner' && uid) {
            //console.log('INSMOD: 1 on 1 - owner 1on1')
            insightsRefCol = this.afs
              .collection('users')?.doc(uid ? uid : '') //
              .collection('user-orgs')?.doc(currOrg)
              .collection('user-activity-pattern-1on1-insights');
          }
          break;
        }
      }

      const querySnapshot:any = (insightsRefCol) ? await insightsRefCol.get().toPromise() : null;
      if (!querySnapshot.empty) {
        let dealInsights: ICardInsightsItem[] | ICardInsights1on1PatternItem[] | ICardInsights1on1Item ;
        dealInsights = querySnapshot?.docs?.map((doc:any) => doc?.data());
        //console.log('List of updated 1on1 insights for owner:', this.currUser.email, dealInsights);
        return dealInsights;
      } else {
        console.log('INSMOD | Cant retrive insights: ', uid, dealId);
        return [];
      }
    } catch (error:any) {
      return [];
      //console.error('Error getting modified 1on1 insights:', error);
      throw error; // Rethrow error to be handled by the caller
    }
  }

  

}
