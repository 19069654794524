<div class="container">
    <div class="menu-filters">Pipeline </div>
    <div class="selected-items">
        <c-badge class="chip" color="primary" shape="rounded-pill" *ngFor="let chip of selectedMultiselects.slice(0,2)">
            {{chip.name}} 
            <span class="chip-close" (click)="removeMultiselect(chip)">
                <svg [cIcon]="icons.cilX" size="l"></svg>
            </span>
        </c-badge>
        <c-badge *ngIf="selectedMultiselects.length - selectedMultiselects.slice(0,2).length > 0" class="chip" color="secondary" shape="rounded-pill"
            [cTooltipVisible]="false" 
            [cTooltip]="'More Pipelines: \n'+morePipelinesTooltip" 
            cTooltipPlacement="bottom"
        >{{(selectedMultiselects.length - selectedMultiselects.slice(0,2).length)+' More'}}</c-badge> 
        <!--<c-badge class="chip" color="primary" shape="rounded-pill" *ngFor="let chip of selectedMultiselects">
            {{chip.name}} 
            <span class="chip-close" (click)="removeMultiselect(chip)">
                <svg [cIcon]="icons.cilX" size="l"></svg>
            </span>
        </c-badge>-->
        <!--<div class="dropdown">
            <button class="dropbtn">
                <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.146894 0.64494C0.19334 0.598377 0.248515 0.561434 0.30926 0.536228C0.370005 0.511021 0.435127 0.498047 0.500894 0.498047C0.566661 0.498047 0.631782 0.511021 0.692528 0.536228C0.753273 0.561434 0.808448 0.598377 0.854894 0.64494L6.50089 6.29194L12.1469 0.64494C12.1934 0.598452 12.2486 0.561576 12.3093 0.536417C12.37 0.511258 12.4352 0.498309 12.5009 0.498309C12.5666 0.498309 12.6317 0.511258 12.6925 0.536417C12.7532 0.561576 12.8084 0.598452 12.8549 0.64494C12.9014 0.691428 12.9383 0.746617 12.9634 0.807357C12.9886 0.868096 13.0015 0.933197 13.0015 0.99894C13.0015 1.06468 12.9886 1.12978 12.9634 1.19052C12.9383 1.25126 12.9014 1.30645 12.8549 1.35294L6.85489 7.35294C6.80845 7.3995 6.75327 7.43645 6.69253 7.46165C6.63178 7.48686 6.56666 7.49983 6.50089 7.49983C6.43513 7.49983 6.37001 7.48686 6.30926 7.46165C6.24852 7.43645 6.19334 7.3995 6.14689 7.35294L0.146894 1.35294C0.100331 1.30649 0.0633878 1.25132 0.0381813 1.19057C0.0129749 1.12983 0 1.06471 0 0.99894C0 0.933173 0.0129749 0.868052 0.0381813 0.807307C0.0633878 0.746562 0.100331 0.691386 0.146894 0.64494Z" fill="#4C0EAF"/>
                </svg>          
            </button>
            <div class="dropdown-content">
                <div>
                    <input type="checkbox" id="selectAll" [checked]="isSelectAllChecked" (change)="selectAllMultiselects($event)">
                    <label for="selectAll" >Select All</label>
                </div>
                <input type="text" (keyup)="search()" [(ngModel)]="searchText" class="formControl" placeholder="Search">
                <div *ngFor="let multiselect of filteredMultiselects" >
                    <input type="checkbox" [id]="multiselect.name" [checked]="multiselect.checked" (change)="selectMultiselect($event, multiselect)">
                    <label [for]="multiselect.name">{{multiselect.name}}</label>
                </div>
            </div>
        </div>-->
        <c-dropdown direction="dropend" variant="nav-item" class="">
            <button
                cButton
                color=""
                [caret]="false"
                cDropdownToggle
                class="py-0"
                >
                <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.146894 0.64494C0.19334 0.598377 0.248515 0.561434 0.30926 0.536228C0.370005 0.511021 0.435127 0.498047 0.500894 0.498047C0.566661 0.498047 0.631782 0.511021 0.692528 0.536228C0.753273 0.561434 0.808448 0.598377 0.854894 0.64494L6.50089 6.29194L12.1469 0.64494C12.1934 0.598452 12.2486 0.561576 12.3093 0.536417C12.37 0.511258 12.4352 0.498309 12.5009 0.498309C12.5666 0.498309 12.6317 0.511258 12.6925 0.536417C12.7532 0.561576 12.8084 0.598452 12.8549 0.64494C12.9014 0.691428 12.9383 0.746617 12.9634 0.807357C12.9886 0.868096 13.0015 0.933197 13.0015 0.99894C13.0015 1.06468 12.9886 1.12978 12.9634 1.19052C12.9383 1.25126 12.9014 1.30645 12.8549 1.35294L6.85489 7.35294C6.80845 7.3995 6.75327 7.43645 6.69253 7.46165C6.63178 7.48686 6.56666 7.49983 6.50089 7.49983C6.43513 7.49983 6.37001 7.48686 6.30926 7.46165C6.24852 7.43645 6.19334 7.3995 6.14689 7.35294L0.146894 1.35294C0.100331 1.30649 0.0633878 1.25132 0.0381813 1.19057C0.0129749 1.12983 0 1.06471 0 0.99894C0 0.933173 0.0129749 0.868052 0.0381813 0.807307C0.0633878 0.746562 0.100331 0.691386 0.146894 0.64494Z" fill="#4C0EAF"/>
                </svg> 
                </button>
            
            <ul cDropdownMenu >
                <li>
                    <button cDropdownItem>
                        <input type="checkbox" id="selectAll" [checked]="isSelectAllChecked" (change)="selectAllMultiselects($event)">
                        <label for="selectAll" >Select All</label>
                    </button>
                
                </li>
                <li>
                    <hr cDropdownDivider/>
                </li>
                <li *ngIf="filteredMultiselects.length == 0">
                    <button class="no-data" cDropdownItem>Loading pipelines...</button>
                </li>
                <li *ngFor="let multiselect of filteredMultiselects" >
                    <button cDropdownItem>
                        <input type="checkbox" [id]="multiselect.name" [checked]="multiselect.checked" (change)="selectMultiselect($event, multiselect)">
                        <label [for]="multiselect.name">{{multiselect.name}}</label>
                    </button>
                </li>
            </ul>
          </c-dropdown>
    </div>    
</div>
