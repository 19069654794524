import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthTokenHttpInterceptor implements HttpInterceptor {

    constructor(
        private auth: AngularFireAuth
    ) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.auth.idToken.pipe(
            take(1),
            switchMap(idToken => {
                let clone = req.clone();
                const currOrg = JSON.parse(localStorage.getItem('orgCodeCurr')!);

                if (idToken) {
                    clone = clone.clone({ headers: req.headers.set('Authorization', 'Bearer ' + idToken) });
                }

                if (!currOrg) {
                    // You can throw an error or handle it as per your need
                    return throwError(() => new Error('Organization code does not exist'));
                }

                if (!req.url.includes('firebasestorage')) { //issues with CORS when storage is requested with body params
                    clone = clone.clone({ headers: clone.headers.set('orgcodecurr', currOrg) 
                    //.set('Access-Control-Allow-Origin', 'http://localhost:4200')
                    //.set('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, OPTIONS')
                    //.set('Access-Control-Allow-Headers', 'Content-Type, Authorization')
                    });
                  }

                return next.handle(clone)
            })
        )

    }
}

export const AuthTokenHttpInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthTokenHttpInterceptor,
    multi: true
}