import { Component, OnInit } from '@angular/core';

import { navItems } from './_nav';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BehaviorSubject, Subject, distinctUntilChanged, filter, map, shareReplay, take, takeUntil, timeout } from 'rxjs';
import { INavData } from '@coreui/angular';
import { OrgsettingService } from 'src/app/shared/services/orgsetting.service';
import { OrgdataService } from 'src/app/shared/services/orgdata.service';
import { environment } from 'src/environments/environment.dev';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent implements OnInit {

  public navItems!: INavData[];// = navItems;
  public showModal: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();
  public isProdEnv: boolean = true;
  

  constructor(private auth: AuthService,
    private orgDat: OrgdataService
  ) {}

  ngOnInit(): void {
    this.isProdEnv = environment.production;

    const adminViews = new Set(['/teamsettings', '/quotasettings', '/m1on1-team']);
    const hideViews = new Set(environment.dataConfig.previewFeatures.hidePreviewFeaturesTabs);
    const hideSections = new Set(environment.dataConfig.previewFeatures.hidePreviewSections);
    const showHiddenForOrgs =  new Set(environment.dataConfig.previewFeatures.showPreviewFeaturesOnlyFor);

    this.navItems = navItems
      .filter((item: any) => !adminViews.has(item?.url))
      .filter((hide: any) => !hideViews.has(hide?.url))
      .filter((hideSection: any) => !hideSections.has(hideSection?.name));
    
    this.auth._navPermit
    .pipe(
      takeUntil(this.destroy$),
      //distinctUntilChanged(),
    )
    .subscribe(() => 
      {
        setTimeout(() => 
          this.auth.isAdmin().pipe(
            //timeout(1000),
            take(1),
            takeUntil(this.destroy$),
            distinctUntilChanged(),
            map(isAdmin => {
              console.log('=============ADMIN', isAdmin);
              if (!isAdmin) {
                return false;
              } else {
                return true;
              }
            })
          ).subscribe(isAdmin => {
            //this.isAdmin = isAdmin; // Set the isAdmin flag based on the result
            let navItemsAccess;
            if (isAdmin) {
              navItemsAccess = navItems;
            } else {
              navItemsAccess = navItems
                .filter((item: any) => !adminViews.has(item?.url))
                //.filter((hide: any) => !hideViews.has(hide?.url))
                //.filter((hideSection: any) => !hideSections.has(hideSection?.name));
            }

            if (showHiddenForOrgs.has(this.orgDat.currOrgCode) || showHiddenForOrgs.has('*')) {
              this.navItems = navItemsAccess;
            } else {
              this.navItems = navItemsAccess
                .filter((hide: any) => !hideViews.has(hide?.url))
                .filter((hideSection: any) => !hideSections.has(hideSection?.name)) 
            }

            console.log('MENU | Nav items: ', this.navItems);
          }), 1000);
      }
    );
    
    

    this.showModal = false;
    //console.log('Load layout');
    //this.userSet.TriggerSomeActionsOnLoadingLayout();

    this.auth._modalAfterLogin
      .pipe(filter(m => m == 'demoorcontact'))
      .pipe(
      shareReplay(1)
    ).subscribe(modal => {
      this.showModal = true;
      console.log('Modal: ', modal);
    });
  }
}
