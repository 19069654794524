<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container class="header-container">
  <c-container [fluid]="true" >
    <button
      toggle="visible"
      cHeaderToggler
      [cSidebarToggle]="sidebarId"
      class="ps-1"
    >
      <svg cIcon
        name="cilMenu"
        size="lg"
      ></svg>
    </button>
    <!-- when hidden filter persist otherwise resets, temporary solution istead of: *ngIf="this.showPipelineFilter"-->
    <app-select-pills-dropdown class="pills-space" [data]="orgPipelines" [hidden]="!this.showPipelineFilter"></app-select-pills-dropdown>
    <div class="pills-space"  style="width: 10px; flex-grow: 1"></div>
    <div 
      *ngIf="!isProdEnv"
      style="color: darkred; font-weight: bold;">
        Warning! This is one of DEVELOPMENT environments!
      </div>
    <!--<button (click)="this.globalFilterPipelines(['Pipeline 1', 'Pipeline 3'])">Add filter to Pipeline 1 & 3</button>
    <button (click)="this.globalFilterPipelines(['Pipeline 1'])">Pipeline 1 only</button>
    <button (click)="this.globalFilterPipelines([])">Remove all filters</button>-->
<!--
    <c-header-nav class="d-none d-lg-flex">
      <c-nav-item>
        <a routerLink="./" cNavLink>
          <svg cIcon name="cilBell" size="lg"></svg>
        </a>
      </c-nav-item>
      <c-nav-item>
        <a routerLink="./" cNavLink>
          <svg cIcon name="cilList" size="lg"></svg>
        </a>
      </c-nav-item>
      <c-nav-item>
        <a routerLink="./" cNavLink>
          <svg cIcon name="cilEnvelopeOpen" size="lg"></svg>
        </a>
      </c-nav-item>
    </c-header-nav>-->
    <c-header-nav class="ms-3">
      <ng-container *ngTemplateOutlet="userDropdownOrg"></ng-container>
    </c-header-nav>
    <c-header-nav class="ms-3">
      <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
      <!--<svg width="50" height="50" xmlns="http://www.w3.org/2000/svg">
        <circle cx="25" cy="25" r="20" stroke="black" stroke-width="2" fill="lightblue" />
        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="24" fill="black">A</text>
      </svg>-->
    </c-header-nav>
    
<!----
  </c-container>
 <c-header-divider></c-header-divider>
  <c-container [fluid]="true">
    <c-breadcrumb-router class="ms-2"></c-breadcrumb-router>
  </c-container>
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button
      cButton
      color=""
      [caret]="false"
      cDropdownToggle
      class="py-0"
    >
      <c-avatar
        cTextColor="primary"
        shape="rounded-1"
        size="md"
        src="{{(this.headerAvatar) ? this.headerAvatar : '' }}"
        status="success"
        referrerpolicy="no-referrer"
      >
      </c-avatar>
    </button>
    <ul cDropdownMenu class="pt-0 pr-5 w-auto">
      <!--<li>
        <h6 cDropdownHeader class="bg-light fw-semibold py-2">Account</h6>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilBell"></svg>
          Updates
          <c-badge class="ms-2 float-end" color="info">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilEnvelopeOpen"></svg>
          Messages
          <c-badge class="ms-2 float-end" color="success">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilTask"></svg>
          Tasks
          <c-badge class="ms-2 float-end" color="danger">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name='cilCommentSquare'></svg>
          Comments
          <c-badge class="ms-auto" color="warning">
            42
          </c-badge>
        </a>
      </li>-->
      <li>
        <h6 cDropdownHeader class="bg-light fw-semibold py-2">Settings</h6>
      </li>
      <li></li>
      <li>
        <a routerLink="./userprofile" cDropdownItem>
          <svg cIcon class="me-2" name="cilUser"></svg>
          Profile
        </a>
      </li>
      <app-default-header-showorgs></app-default-header-showorgs>
      

      <!--<li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilSettings"></svg>
          Settings
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilCreditCard"></svg>
          Payments
          <c-badge class="ms-2 float-end" color="secondary">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilFile"></svg>
          Projects
          <c-badge class="ms-2 float-end" color="primary">
            42
          </c-badge>
        </a>
      </li>-->
      <li>
        <hr cDropdownDivider/>
      </li>
      <li>
        <a routerLink="./" cDropdownItem (click)="authService.SignOut()">
          <svg cIcon class="me-2" name="cilLockLocked"></svg>
          Log Out
        </a>
      </li>
      <li></li>
    </ul>
  </c-dropdown>
</ng-template>

<ng-template #userDropdownOrg>
  <c-dropdown alignment="start" variant="nav-item">
    <button
      cButton
      color=""
      [caret]="false"
      cDropdownToggle
      class="py-0"
    >
    <c-badge class="chip" color="primary-alt" shape="rounded-pill"
    *ngIf="currentOrgSelected"
    >{{(currentOrgSelected != 'null' && currentOrgSelected != 'undefined') ? currentOrgSelected.toLocaleUpperCase() : ' SELECT ORG... '}}
    </c-badge>
    </button>
    <ul cDropdownMenu class="pt-0 pr-5 w-auto">
      <li>
        <h6 cDropdownHeader class="bg-light fw-semibold py-2">Your Orgs</h6>
      </li>
      <li></li>
      <app-default-header-showorgs></app-default-header-showorgs>
      <li></li>
    </ul>
  </c-dropdown>
</ng-template>

