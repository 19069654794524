import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { User } from './user';
import { AuthService } from './auth.service';
import { OrgsettingService } from './orgsetting.service';
import { IInviteEntry, IMemberEntry, IOrgInvite, IQuotaEntry } from 'src/app/shared/interfaces/invites';
import { Observable, Subject, combineLatest, of, skip, take } from 'rxjs';
import { IOwnerDataByOwnerByPipeline, IOwnerDataByOwnerByPipelineSum, IOwnerLisItem, IQuotaDataByOwner } from '../interfaces/ownerdata';
import { HttpClient } from '@angular/common/http'; 
import { environment } from 'src/environments/environment.dev';
import { OrgdataService } from './orgdata.service';
import { AnalyticsService } from './analytics.service';

@Injectable({
  providedIn: 'root'
})
export class UserInvitationsService {

  //private currUser!: User | any;
  public _toggleAddMembersModal = new Subject<{toggle: boolean, editMember: IInviteEntry | null}>();
  public currOrgCode!: string;
  public currUser!: User | any;
  public _orgMembersList!: Observable<IMemberEntry[]|any>;
  public _orgMembersItem!: Observable<IMemberEntry|any>;
  public _dataSumOwnerLevelQuotaSubject = new Subject<IQuotaDataByOwner | []>();

  constructor(private afs: AngularFirestore,
              private afAuth: AngularFireAuth,
              private orgSet: OrgsettingService,
              private auth: AuthService,
              private http: HttpClient,
              private ga: AnalyticsService
  ) {
     
    this.orgSet.$orgChange
    .subscribe(orgCode => {
      console.log('ORG CODE set', orgCode);
      this.currOrgCode = orgCode;
    });

    this.auth._checkInvites.subscribe(()=> {
      this.checkNewInvites();
    })
/*

--
    this.afAuth.authState
    .subscribe((user) => {
      if (user) {
        this.currUser = user;
        console.log('INVITES1: Get curr user:', user);
      }
      });

*/
      this.auth._getCurrUser.subscribe(user => {
      
        this.currUser = user;
        console.log('INVITES: Get curr user:', user);
      })

  }

  private postAPISaveMamberToInvitations(memberEmail: string, inviteEntry: IOrgInvite, memberEntry: IMemberEntry,orgCode: string): Promise<void> {
    //if (orgCode == null) return of(null).toPromise();
    return this.http.post<void>(environment.firebase.functionsUrl+'/saveMemberToInvitation',{email: memberEmail, inviteentry: inviteEntry, memberentry: memberEntry,orgcode: orgCode}).toPromise();
  }

  private postAPIDeleteMemberFromOrg(memberEmail: string, orgCode: string): Promise<void> {
    //if (orgCode == null) return of(null).toPromise();
    return this.http.post<void>(environment.firebase.functionsUrl+'/deleteMemberFromOrg',{email: memberEmail, orgcode: orgCode}).toPromise();
  }

  //1. check if user has any invites
  //2. if user has document with name of organization then add user access to that org
  //3. add org to user config on firebase

  /* inviations */

  public checkNewInvites() {
    console.log('Checking logged user invites........');
    this.afAuth.authState.subscribe(user => {
      
      //this.currUser = user;
      //console.log('INVITES: Get curr user:', user);
      this.checkInvitesToOrg(user);
      //+ in future: check invites to teams
    })

    
    

  }

  private checkInvitesToOrg(user: User | any) {
    //1. Check if exists email doc in invites-to-org
    const orgInvites = this.getInviteOrgDocs(user);
    orgInvites.then(
      (orgInvites) => this.applyInvitesToOrg(user, orgInvites)
    );
    ;
    //2. if not exists --> do nothing
    //3. if exists --> take invite details (org name, access level)
    //4. add new org access details to user
    //5. inform on new accesses: org name, change of permision (You've been granted new org access by Who)
  }

private async getInviteOrgDocs(user: User):Promise<IOrgInvite[]> {
  console.log('Get invites org for: ', user);
  try {
     const inviteUserToOrgCol: AngularFirestoreCollection<AngularFirestoreDocument<IOrgInvite[]>[]> = this.afs
    .collection('invites-to-org')?.doc(user?.email)
    .collection('invites-to-org');

    const querySnapshot:any = await inviteUserToOrgCol.get().toPromise();
    if (!querySnapshot.empty) {
      let orgInvites: IOrgInvite[];
      orgInvites = querySnapshot?.docs?.map((doc:any) => doc?.data());
      console.log('Curr user org invites', orgInvites);
      return orgInvites;
    } else {
      //console.log('No updated insights for deal:', dealId);
      return [];
    }
  } catch (error:any) {
    //console.error('Error getting modified deal insights:', error);
    throw error; // Rethrow error to be handled by the caller
  }
}

private applyInvitesToOrg(user: User, orgInvites: IOrgInvite[]) {
  console.log('Apply invites to orgs')
  orgInvites.forEach(orginvite => {
    // 1. if code doesnt exist then add org to user's org codes list in UID
    ///if (orginvite.allowAccess) {
      //add access to org
      console.log('Add access to org', orginvite.orgCode);
      this.orgSet.updateOrgWithRoles(user, orginvite);
      // 2. update orgRoles for user
    ///}

    ////if (!orginvite.allowAccess){
      //to do: remove access to org
    ////}
    
  })
}

private deleteUsedOrgInvite() {
  // delete used invite at the end
}

private createNewOrgInvite() {
  // invite new users
} 

public getOrgMembers():Observable<IMemberEntry[]> {
  try {
    const currOrgCode = JSON.parse(localStorage.getItem('orgCodeCurr')!);
    this._orgMembersList = this.afs
      .collection('org-settings')
      .doc(currOrgCode)
      .collection('orgmembers')
      .valueChanges();
  
    return this._orgMembersList;
  } catch (e:any) {
    throw Error('Cant load organization members: ', e);
  }

}

public inviteMember(formInput:IInviteEntry){
  console.log('INVITE: Invite member',formInput);
  //1. save member to org-settings
  //2. add invite entry to invites-to-org
  //3. send email
  let role = [formInput?.role];
  const orgMember: IMemberEntry = {
    fullName: '('+formInput?.email+')',
    currentRoles: role,
    inviteStatus: 'invited',
    email: formInput.email,
    invitedMillis: new Date().getTime(),
    avatarUrl: null,
    uid: '',
    quotaSettings: {
      quotaPeriod: null,
      quotaAmount: null
    },
    lastLoggedMillis: null
  };

  const invite: IOrgInvite = {
    allowAccess: true, //if false then un-invite to org
    invitedBy: this.currUser?.displayName,
    invitedTimestamp: new Date().getTime(), //unix millis
    invitedDateString: new Date().toLocaleDateString(),
    orgCode: this.currOrgCode,
    orgRoles: role
  }
/* momved to backend:
  this.saveMemberToOrgList(formInput.email, orgMember).then(() => {
    console.log('New Member added', formInput);
  })
  .catch(error => {
    console.error('Error removing document: ', error);
  });*/

  return this.postAPISaveMamberToInvitations(formInput.email, invite, orgMember, this.currOrgCode)
  .then(()=> this.ga.LogClickEvent({
    _eventCategory: 'Invite',
    _eventAction: 'Invite Member',
    _eventLabelName: 'Invite Member',
    _eventDataId: environment.dataConfig.nonClientEmailMatch.some(substring => orgMember.email.includes(substring)) ? 'non-client' : 'client',
    _eventLabelValue: 1
  }))
  .catch((error) => {
    if (error.status != 200) 
      console.error('Error adding invite document: ', formInput, error);
  });
}

public deleteMember(email: string){
  
  return this.postAPIDeleteMemberFromOrg(email,this.currOrgCode)
  .then(()=> this.ga.LogClickEvent({
    _eventCategory: 'Invite',
    _eventAction: 'Un-invite Member',
    _eventLabelName: 'Un-invite Member',
    _eventDataId: environment.dataConfig.nonClientEmailMatch.some(substring => email.includes(substring)) ? 'non-client' : 'client',
    _eventLabelValue: -1
  }))
  .catch(error => {
    if (error.status != 200) 
      console.error('Error removing document: ', email, error);
  });
  /*
  this.deleteFromOrgList(email).then(() => {
    console.log('Successfully deleted user', email);
    window.alert('Successfully deleted user');
  })
  .catch(error => {
    console.error('Error removing document: ', error);
  });

  //+ remove invitation for this user PERMISSIONS
  this.deleteInvitationToOrg(email).then(() => {
    console.log('Successfully deleted invitation to org - if existed', email);
    window.alert('Successfully deleted invitation to org - if existed');
  })
  .catch(error => {
    console.error('Error removing document: ', error);
  });
*/

}


/* adding new member, sending invites */
// moved to backend:::
/*
private saveMemberToOrgList(email: string, docEntry: IMemberEntry):Promise<void>{
  const memberRef: AngularFirestoreDocument<IMemberEntry> = this.afs
  .collection('org-settings')
  .doc(this.currOrgCode)
  .collection('orgmembers')
  .doc(email);

  return memberRef.set(docEntry, {
      merge: true,
    })
}*/
/*
private saveMemberToInvitation(email: string, inviteEntry: IOrgInvite): Promise<void> { //MOVE TO BACKEND due to permissions
  const inviteRef: AngularFirestoreDocument<IOrgInvite> = this.afs
  .collection('invites-to-org')
  .doc(email)
  .collection('invites-to-org')
  .doc(this.currOrgCode)
  ;

  return inviteRef.set(inviteEntry, {
      merge: true,
    })
}
*/

/* removing members, deleting invites */
/* moved to backend:
private deleteFromOrgList(email: string): Promise<void> {
  return this.afs.collection('org-settings')
  .doc(this.currOrgCode)
  .collection('orgmembers')
  .doc(email).delete();
}

private deleteInvitationToOrg(email:string): Promise<void>{ //MOVE TO BACKEND due to permissions
  return this.afs.collection('invites-to-org')
  .doc(email)
  .collection('invites-to-org')
  .doc(this.currOrgCode).delete();
}
*/
/*
deleteDocument(collectionPath: string, documentId: string): Promise<void> {
  return this.afs.collection(collectionPath).doc(documentId).delete();
}*/


/* QUOTA functions - to do: move to separate service when more functions */

public updateMemberQuota(email: string, quota: IQuotaEntry){
  const memberRef: AngularFirestoreDocument<IMemberEntry> = this.afs
  .collection('org-settings')
  .doc(this.currOrgCode)
  .collection('orgmembers')
  .doc(email);
  console.log('Email', email);

  memberRef.get().subscribe((doc:any) => {
    /*try {*/
      if (doc?.exists) {
        console.log('Doc exists: ', doc);
        
        const member: IMemberEntry = doc.data();
        const updatedMember: IMemberEntry = {
          fullName: member?.fullName,
          currentRoles: member?.currentRoles,
          inviteStatus: member?.inviteStatus,
          email: member?.email,
          invitedMillis: member?.invitedMillis,
          avatarUrl: (member?.avatarUrl) ? member?.avatarUrl : null,
          uid: member?.uid,
          quotaSettings: {
            quotaPeriod: quota?.quotaPeriod,
            quotaAmount: quota?.quotaAmount
          },
          lastLoggedMillis: (member?.lastLoggedMillis) ? member?.lastLoggedMillis : null
        }
        
        return memberRef.set(updatedMember, {
          merge: true,
        })
      } else throw Error('Member document was not found');
  });
  
  
}

public getOrgMember(email: string):Observable<IMemberEntry> {
  const currOrgCode = JSON.parse(localStorage.getItem('orgCodeCurr')!);
  this._orgMembersItem = this.afs
    .collection('org-settings')
    .doc(currOrgCode)
    .collection('orgmembers')
    .doc(email)
    .valueChanges();

  return this._orgMembersItem;
}

public calculateQuotaData(member: IMemberEntry, showForEmail: string, ownerLevelData: IOwnerDataByOwnerByPipeline | null): IQuotaDataByOwner {
  const daysRemaining: number = this.orgSet.daysQuarterRemaining();
  const quota = (member?.quotaSettings?.quotaAmount || member?.quotaSettings?.quotaAmount == 0) ?  member?.quotaSettings?.quotaAmount : null;
  
  const quotaDone = (quota && ownerLevelData && ownerLevelData?.ownerCurrWonAmt > 0) ? Math.round(ownerLevelData?.ownerCurrWonAmt/quota*100) : 0;

  const ownerLevelQuota = {
    dealOwnerId: 999,
    dealOwnerEmail: showForEmail,
    quotaDaysToPeriodEnd: daysRemaining,
    quotaDone: quotaDone,
    quotaMissing: 100 - quotaDone,
    quotaAmount: quota
  }
  //console.log('Quota: ------>',quota, quotaDone, ownerLevelQuota, ownerLevelData?.ownerCurrWonAmt);

  return ownerLevelQuota;

}

public getOwnerDataQuota(showDataFor: IOwnerLisItem, ownerLevelData: IOwnerDataByOwnerByPipeline | null): Promise<IQuotaDataByOwner> {
  //this._dataSumOwnerLevelQuotaSubject.next([]);
  return new Promise((resolve, reject) => {
    this.getOrgMember(showDataFor?.dealOwnerEmail)
    //.pipe(map((data: IQuotaDataByOwner[]) => data.filter(data => data?.dealOwnerEmail == this.showDataForMember?.dealOwnerEmail)))
    //.pipe(map((data: IOwnerDataByOwnerByPipeline[]) => this.applyPipelinesFilter(pipelinesFilter, data))
    //.pipe(map((filtered: IOwnerDataByOwnerByPipeline[]) => this.sumOwnerLevelDataByGlobalFilter(filtered, pipelinesFilter)))
    .subscribe(
      (member: IMemberEntry) => {

        const quotaData = this.calculateQuotaData(member,showDataFor?.dealOwnerEmail,ownerLevelData);
        resolve(quotaData);
        this._dataSumOwnerLevelQuotaSubject.next(quotaData);
      },
      error => {
        reject(error); // Reject if there is an error in fetching data
      }
    );
  });
}


  /** check accesses */

/*
  async isUserAllowedToLog2(loggingEmail:string):Promise<boolean> {
    
    const userInviteRef: AngularFirestoreDocument<any> = this.afs
    .collection('app-access')
    .doc(loggingEmail);

    let userDocExists:boolean = false;
    userInviteRef.get().subscribe((doc:any) => {
      if (doc.exists) {
        userDocExists = true
      } else {
        userDocExists = false
        //not allowed, log out, remove uid entry
      }
    });
    console.log('is User allowed to log?', loggingEmail, userDocExists);
    return userDocExists;
  }
/*
  async isUserAllowedToLog(loggingEmail: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
        const userInviteRef: AngularFirestoreDocument<any> = this.afs
            .collection('app-access')
            .doc(loggingEmail);

        userInviteRef.get().subscribe((doc: any) => {
            if (doc.exists) {
                resolve(true); // User is allowed to log
            } else {
                // User is not allowed to log
                // Perform any necessary actions here, such as logging out and removing UID entry
                reject(false);
            }
        });
    });
}*/

}

/* when inviting:
- check if org invite exists and override with new
*/