<!--sidebar-->
<c-sidebar
  #sidebar="cSidebar"
  class="d-print-none sidebar sidebar-fixed"
  id="sidebar"
  visible
>
  <c-sidebar-brand
    [brandFull]="{
      src: 'assets/img/brand/AIMARSv3_logo_white.png',
      width: 130,
      height: 18,
      alt: 'Aimars Logo'
    }"
    [brandNarrow]="{
      src: 'assets/img/brand/AIMARSv3_logo_white_icononly.png',
      width: 30,
      height: 18,
      alt: 'CoreUI Logo'
    }"
    routerLink="./"
  />

  <ng-scrollbar pointerEventsMethod="scrollbar">
    <c-sidebar-nav
      [navItems]="navItems"
      dropdownMode="close"
    />
  </ng-scrollbar>
  <c-sidebar-toggler
    *ngIf="!sidebar.narrow"
    toggle="unfoldable"
    cSidebarToggle="sidebar"
  />
</c-sidebar>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
  <!--app-header-->
  <app-default-header class="mb-4 d-print-none header header-sticky" [ngClass]="{'nonprod-env':!isProdEnv}" position="sticky" sidebarId="sidebar" [isProdEnv]="isProdEnv"/>
  <!--app-body-->
  <div class="body flex-grow-1 px-3">
    <c-container breakpoint="lg" class="h-auto">
      <router-outlet/>
      <app-modal-demoorcontact [showModal]="showModal"></app-modal-demoorcontact>
      <app-popup-alert-belt></app-popup-alert-belt>
      <app-popup-confirm></app-popup-confirm>
    </c-container>
  </div>
  <!--app footer-->
  <!--<app-default-footer/>-->
</div>
