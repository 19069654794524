<ng-container *ngIf="this.showOrgList">
    <li>
        <hr cDropdownDivider/>
      </li>
    <ng-container *ngFor="let o of this.userOrgCodes; index as i">
    <li>
      <a 
        (click) = "chooseOrg(o)"
        [active]="(userOrgCurrent == o ? true : false)"
        cDropdownItem>
        <svg cIcon class="me-2" name="cilBuilding"></svg>
        {{o?.toUpperCase()}}
      </a>
    </li>
  </ng-container>
</ng-container>