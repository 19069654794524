import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Subject, distinctUntilChanged, shareReplay, takeUntil } from 'rxjs';
import { OrgsettingService } from 'src/app/shared/services/orgsetting.service';

@Component({
  selector: 'app-default-header-showorgs',
  templateUrl: './default-header-showorgs.component.html',
  styleUrls: ['./default-header-showorgs.component.scss']
})
export class DefaultHeaderShoworgsComponent implements OnInit {

  constructor(public orgSet: OrgsettingService) {}

  public showOrgList: boolean =  false;
  public userOrgCodes: string[] = [];
  public userOrgCurrent: string = '';
  private destroy$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    setTimeout(()=>{ 
      this.getOrgCodes();
      this.userOrgCurrent = JSON.parse(localStorage.getItem('orgCodeCurr')!);
      console.log('Show orgs on the list... init', this.userOrgCodes);
    },1000);

    this.orgSet.$orgChange
    //this.orgSet._passUserAndOrg
    //.pipe(distinctUntilChanged())
    .pipe(takeUntil(this.destroy$))
    .subscribe(currOrgCode => {
      setTimeout(()=>{ 
      this.getOrgCodes();
      this.userOrgCurrent = currOrgCode;//JSON.parse(localStorage.getItem('orgCodeCurr')!);
      console.log('Org change: ', this.userOrgCodes, 'Change to:',this.userOrgCurrent );
    },1000);
    });
    //this.uSet.TriggerSomeActionsOnLoadingLayout();
  
  }

  getOrgCodes(){
    this.userOrgCodes = JSON.parse(localStorage.getItem('orgCodes')!)?.sort();
    this.showOrgList = (this.userOrgCodes != null && this.userOrgCodes?.length > 0 ) ? true : false;
  }

  chooseOrg(orgCode: string){
    this.orgSet.$orgChange.next(orgCode);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all observables to prevent memory leaks
    this.destroy$.next();
    this.destroy$.complete();
  }

}
