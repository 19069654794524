import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentSnapshot } from '@angular/fire/compat/firestore';
import { ICardInsightsItem, IInsightState, TDealEngScoreDetailsType } from '../interfaces/dealdata';
import { AuthService } from './auth.service';
import { User } from './user';
import { ICardInsights1on1Item } from '../interfaces/ownerdata';
import { Subject } from 'rxjs';
import { IOffCanva1on1PatternDetails, IOffCanvaDetails, TInsightCategory, TInsightLevel } from '../interfaces/datastructures';

@Injectable({
  providedIn: 'root'
})
export class ActivitydataService {

  
  public _showDetailsInOffCanva = new Subject<IOffCanvaDetails>(); //view operations
  public _show1on1DetailsInOffCanva = new Subject<IOffCanva1on1PatternDetails>(); //view operations
  public _higlightDetailsType = new Subject<TDealEngScoreDetailsType|null>(); //view operations
  public _activateInsightsSwitcher = new Subject<IInsightState>();

  currUser!: User;
  constructor(public afs: AngularFirestore,
              private auth: AuthService
    ) {

      this.auth._getCurrUser.subscribe(user => {
      
        this.currUser = user;
        //console.log('Subi to user: ', this.currUser.uid);
      })
    }

  async updateInsight<T extends ICardInsightsItem | ICardInsights1on1Item>(changedInsight: T, insightCategory: TInsightCategory): Promise<void[]> { //, insightType: TInsightCategory
    const promises: Promise<void>[] = [];
    const currOrg = JSON.parse(localStorage.getItem('orgCodeCurr')!);
    
    changedInsight.lastStatusBy = this.currUser.email;
    changedInsight.lastUpdated = new Date().getTime();

    //if ('category' in changedInsight && 'topic' in changedInsight) { // category1 exists on 1 on 1 insights
    console.log('insight category ', insightCategory);
    if (insightCategory == 'oneonone') {
      const org1on1DealActivitiesRef = this.afs
      .collection('org-data')?.doc(currOrg)
      .collection('deal-activity')?.doc(changedInsight?.dealId?.toString())
      .collection('deal-1on1-insights')?.doc(changedInsight?.insightId?.toString());
    promises.push(org1on1DealActivitiesRef.set(changedInsight, { merge: true }));

      const user1on1ActivitiesRef = this.afs
        .collection('users')?.doc(this.currUser.uid)
        .collection('user-orgs')?.doc(currOrg)
        .collection('user-1on1-insights')?.doc(changedInsight?.insightId?.toString());
      promises.push(user1on1ActivitiesRef.set(changedInsight, { merge: true }));
    } else {
      const orgDealActivitiesRef = this.afs
        .collection('org-data')?.doc(currOrg)
        .collection('deal-activity')?.doc(changedInsight?.dealId?.toString())
        .collection('deal-insights')?.doc(changedInsight?.insightId?.toString());
      promises.push(orgDealActivitiesRef.set(changedInsight, { merge: true }));

      const userDealActivitiesRef = this.afs
        .collection('users')?.doc(this.currUser.uid)
        .collection('user-orgs')?.doc(currOrg)
        .collection('user-activity-insights')?.doc(changedInsight?.insightId?.toString());
      promises.push(userDealActivitiesRef.set(changedInsight, { merge: true }));
    }

    return Promise.all(promises);
  }
  /*

  async getModifiedOrgDealInsights(dealId: string):Promise<ICardInsightsItem[]> {
    // 1. go to org-data collection (check if exists first)
    // 2. go to org document (org code) (check if exists first)
    // 3. go to deal-activity collection (check if exists first)
    // 4. go to dealid document (check if exists first)
    // 5. go to insights-activity collection
    
      try {
        const currOrg = JSON.parse(localStorage.getItem('orgCodeCurr')!);
        //console.log('Get modified deal insights for org',currOrg);

        const dealInsightsRefCol: AngularFirestoreCollection<AngularFirestoreDocument<ICardInsightsItem>[]> = this.afs
        .collection('org-data')?.doc(currOrg)
        .collection('deal-activity')?.doc(dealId?.toString())
        .collection('deal-insights');

        const querySnapshot:any = await dealInsightsRefCol.get().toPromise();
        if (!querySnapshot.empty) {
          let dealInsights: ICardInsightsItem[];
          dealInsights = querySnapshot?.docs?.map((doc:any) => doc?.data());
          //console.log('List of updated insights for deal:', dealId, dealInsights);
          return dealInsights;
        } else {
          //console.log('No updated insights for deal:', dealId);
          return [];
        }
      } catch (error:any) {
        //console.error('Error getting modified deal insights:', error);
        throw error; // Rethrow error to be handled by the caller
      }
   
  }

  async getModifiedOwnerInsights(uid:string | null, currOrg: string):Promise <ICardInsightsItem[]> {
    try {
      
      if(uid) {
      const dealInsightsRefCol: AngularFirestoreCollection<AngularFirestoreDocument<ICardInsightsItem>[]> = this.afs
      .collection('users')?.doc(uid) //
      .collection('user-orgs')?.doc(currOrg)
      .collection('user-activity-insights');    
      
      const querySnapshot:any = await dealInsightsRefCol.get().toPromise();
        if (!querySnapshot.empty) {
          let dealInsights: ICardInsightsItem[];
          dealInsights = querySnapshot?.docs?.map((doc:any) => doc?.data());
          //console.log('List of updated insights for owner:', this.currUser.email, dealInsights);
          return dealInsights;
        } else {
          //console.log('No updated insights for owner:', this.currUser.email);
          return [];
        }

      } else return [];
      
    } catch (error:any) {
      //console.error('Error getting modified deal insights:', error);
      throw error; // Rethrow error to be handled by the caller
    }
      

    //1. go to user document
    //2. go to user orgs collection
    //3. go to orgcode document
    // 3. go to deal-activity collection
    // 4. go to dealid document
    // 5. go to insights-activity collection
    // 6. read documents (updated insights) to the front end
  }

  async getModifiedOrg1on1DealInsights(dealId: string):Promise<ICardInsightsItem[]> {
    // 1. go to org-data collection (check if exists first)
    // 2. go to org document (org code) (check if exists first)
    // 3. go to deal-activity collection (check if exists first)
    // 4. go to dealid document (check if exists first)
    // 5. go to insights-activity collection
    
      try {
        const currOrg = JSON.parse(localStorage.getItem('orgCodeCurr')!);
        //console.log('Get modified deal insights for org',currOrg);

        const dealInsightsRefCol: AngularFirestoreCollection<AngularFirestoreDocument<ICardInsightsItem>[]> = this.afs
        .collection('org-data')?.doc(currOrg)
        .collection('deal-activity')?.doc(dealId?.toString())
        .collection('deal-1on1-insights');

        const querySnapshot:any = await dealInsightsRefCol.get().toPromise();
        if (!querySnapshot.empty) {
          let dealInsights: ICardInsightsItem[];
          dealInsights = querySnapshot?.docs?.map((doc:any) => doc?.data());
          //console.log('List of updated insights for deal:', dealId, dealInsights);
          return dealInsights;
        } else {
          //console.log('No updated insights for deal:', dealId);
          return [];
        }
      } catch (error:any) {
        //console.error('Error getting modified deal insights:', error);
        throw error; // Rethrow error to be handled by the caller
      }
   
  }

  async getModifiedOwner1on1Insights(uid: string | null, currOrg: string) { //same code as above except firebase node (to unify)
    try {

      const dealInsightsRefCol: AngularFirestoreCollection<AngularFirestoreDocument<ICardInsightsItem>[]> = this.afs
      .collection('users')?.doc(uid ? uid : '') //
      .collection('user-orgs')?.doc(currOrg)
      .collection('user-1on1-insights');

      const querySnapshot:any = await dealInsightsRefCol.get().toPromise();
      if (!querySnapshot.empty) {
        let dealInsights: ICardInsightsItem[];
        dealInsights = querySnapshot?.docs?.map((doc:any) => doc?.data());
        //console.log('List of updated 1on1 insights for owner:', this.currUser.email, dealInsights);
        return dealInsights;
      } else {
        //console.log('No updated 1on1 insights for owner:', this.currUser.email);
        return [];
      }
    } catch (error:any) {
      return [];
      //console.error('Error getting modified 1on1 insights:', error);
      throw error; // Rethrow error to be handled by the caller
    }
  }
*/
  async getModifiedInsightsByType(uid: string | null, dealId: string | null | string[], currOrg: string, insightCategory: TInsightCategory, insightLevel: TInsightLevel) { //same code as above except firebase node (to unify)
    try {
      let insightsRefCol: AngularFirestoreCollection<AngularFirestoreDocument<ICardInsightsItem>[]>| null = null;

      if (!uid && !dealId) {
        throw Error('Missing parameter to update insights');
      }
      
      if (insightCategory === 'deal') {
        if (insightLevel === 'deal' && dealId) {
          //console.log('INSMOD: insight - org deal')
          insightsRefCol = this.afs
          .collection('org-data')?.doc(currOrg)
          .collection('deal-activity')?.doc(dealId?.toString())
          .collection('deal-insights');
        }

        if (insightLevel === 'owner' && uid) {
          //console.log('INSMOD: insight - owner deal')
          insightsRefCol = this.afs
          .collection('users')?.doc(uid ? uid : '') //
          .collection('user-orgs')?.doc(currOrg)
          .collection('user-activity-insights'); 
        }

      }

      if (insightCategory === 'oneonone') {

        if (insightLevel === 'deal' && dealId) {
          //console.log('INSMOD: 1 on 1 - org 1on1')
          insightsRefCol = this.afs
          .collection('org-data')?.doc(currOrg)
          .collection('deal-activity')?.doc(dealId?.toString())
          .collection('deal-1on1-insights');
        }

        if (insightLevel === 'owner' && uid) {
          //console.log('INSMOD: 1 on 1 - owner 1on1')
          insightsRefCol = this.afs
            .collection('users')?.doc(uid ? uid : '') //
            .collection('user-orgs')?.doc(currOrg)
            .collection('user-1on1-insights');
        }
/*
        if (insightLevel === 'org' && dealId) {
          console.log('OFF | INSMOD: 1 on 1 insights- by deals');
          insightsRefCol = this.afs
            .collection('org-data')?.doc(currOrg)
            //.collection('deal-activity', ref => ref.where('dealId', 'in', dealId)) // Query deal-activity collection
            .collection('deal-activity', ref =>
              ref.where('__name__', 'in', dealId) // Query documents where the document ID is in the array
            )
            //.snapshotChanges() // Get the snapshots (including metadata like IDs)
            /*.subscribe(dealActivitiesSnapshot => {
              // Loop through each deal-activity document
              dealActivitiesSnapshot.forEach(dealDocSnapshot => {
                // Access the sub-collection 'deal-1on1-insights' within the current deal-activity document
                const dealDocId = dealDocSnapshot.payload.doc.id;
                console.log('OFF | Doc id', dealDocId);
/*
                // Now query the sub-collection (deal-1on1-insights) within each deal-activity document
                const insightsRefCol = this.afs
                  .collection('org-data')?.doc(currOrg)
                  .collection('deal-activity')
                  .doc(dealDocId) // Document reference for the current deal-activity
                  .collection('deal-1on1-insights'); // Access the sub-collection

                // Fetch data from the 'deal-1on1-insights' sub-collection
                insightsRefCol
                  .snapshotChanges() // Get the snapshot of the 'deal-1on1-insights' sub-collection
                  .subscribe(insightsSnapshot => {
                    insightsSnapshot.forEach(insightDoc => {
                      console.log(insightDoc.payload.doc.data()); // Access and log the data from the sub-collection
                    });
                  });
              })
            });

        }*/
      }

      const querySnapshot:any = (insightsRefCol) ? await insightsRefCol.get().toPromise() : null;
      if (!querySnapshot.empty) {
        let dealInsights: ICardInsightsItem[];
        dealInsights = querySnapshot?.docs?.map((doc:any) => doc?.data());
        //console.log('List of updated 1on1 insights for owner:', this.currUser.email, dealInsights);
        return dealInsights;
      } else {
        console.log('Cant retrive insights: ', uid, dealId);
        return [];
      }
    } catch (error:any) {
      return [];
      //console.error('Error getting modified 1on1 insights:', error);
      throw error; // Rethrow error to be handled by the caller
    }
  }

  

}
